/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import {
  FrontPageAds,
  DashboardAds,
  ConfirmationAds,
  FlightResultsAds,
} from "./advertThunk";

const initialState = {
  FRONT_PAGE_ADS: null,
  DASHBOARD_ADS: null,
  FLIGHT_RESULT_ADS: null,
  CONFIRMATION_ADS: null,
  advertLoader: false,
};

export const advertSlice = createSlice({
  name: "advert",
  initialState,
  reducers: {},
  extraReducers: {
    [FrontPageAds.pending]: (state) => {
      state.advertLoader = true;
    },
    [FrontPageAds.fulfilled]: (state, action) => {
      state.advertLoader = false;
      state.FRONT_PAGE_ADS = action.payload;
    },
    [FrontPageAds.rejected]: (state) => {
      state.isLoading = false;
    },
    [DashboardAds.pending]: (state) => {
      state.advertLoader = true;
    },
    [DashboardAds.fulfilled]: (state, action) => {
      state.advertLoader = false;
      state.DASHBOARD_ADS = action.payload;
    },
    [DashboardAds.rejected]: (state) => {
      state.isLoading = false;
    },
    [FlightResultsAds.pending]: (state) => {
      state.advertLoader = true;
    },
    [FlightResultsAds.fulfilled]: (state, action) => {
      state.advertLoader = false;
      state.FLIGHT_RESULT_ADS = action.payload;
    },
    [FlightResultsAds.rejected]: (state) => {
      state.isLoading = false;
    },
    [ConfirmationAds.pending]: (state) => {
      state.advertLoader = true;
    },
    [ConfirmationAds.fulfilled]: (state, action) => {
      state.advertLoader = false;
      state.CONFIRMATION_ADS = action.payload;
    },
    [ConfirmationAds.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default advertSlice.reducer;
