/* eslint-disable no-unused-vars */
import React from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import {
  MdAirplanemodeActive,
  MdSecurity,
  MdOutlineHail,
} from "react-icons/md";
import { FaBuilding, FaPassport, FaCar, FaGamepad } from "react-icons/fa";
import styles from "./styles.module.scss";

function SideBar() {
  const { activePage } = useSelector((state) => state.sidebar);

  const { t } = useTranslation();

  return (
    <nav className={`${styles.sidebar} z-30 dontPrint`}>
      <div className={styles.wrapper}>
        <ul className={styles.navLinks}>
          <li className={styles.link}>
            <Link href="/">
              <div
                className={`${styles.linkCntr} ${
                  activePage === "flight" ? styles.active : ""
                }`}
              >
                <MdAirplanemodeActive className={styles.icon} />
                <span className={styles.linkName}>{t("flights_sidebar")}</span>
              </div>
            </Link>
          </li>
          <li className={styles.link}>
            <Link href="/insurance">
              <div
                className={`${styles.linkCntr} ${
                  activePage === "insurance" ? styles.active : ""
                }`}
              >
                <MdSecurity className={styles.icon} />
                <span className={styles.linkName}>
                  {t("insurance_sidebar")}
                </span>
              </div>
            </Link>
          </li>
          {/* <li className={styles.link}>
            <Link href="/stays">
              <div
                className={`${styles.linkCntr} ${
                  activePage === "stays" ? styles.active : ""
                }`}
              >
                <FaBuilding className={styles.icon} />
                <span className={styles.linkName}>{t("stays_sidebar")}</span>
              </div>
            </Link>
          </li> */}
          <li className={`${styles.link} ${styles.comingSoon}`}>
            <img src="/grey-icon.png" alt="" width="22" height="22" />
          </li>
          <li className={`${styles.link} ${styles.comingSoon}`}>
            <img src="/grey-icon.png" alt="" width="22" height="22" />
          </li>
          <li className={`${styles.link} ${styles.comingSoon}`}>
            <img src="/grey-icon.png" alt="" width="22" height="22" />
          </li>
          <li className={`${styles.link} ${styles.comingSoon}`}>
            <img src="/grey-icon.png" alt="" width="22" height="22" />
          </li>
          <li className={`${styles.link} ${styles.comingSoon}`}>
            <img src="/grey-icon.png" alt="" width="22" height="22" />
          </li>
          {/* <li className={styles.link}>
            <Link href="/experience">
              
                <div
                  className={`${styles.linkCntr} ${
                    activePage === "experience" ? styles.active : ""
                  }`}
                  onClick={() => localStorage.setItem("currentPage", "")}
                >
                  <FaGamepad className={styles.icon} />
                  <span className={styles.linkName}>Experience</span>
                </div>
              
            </Link>
          </li>
          <li className={styles.link}>
            <Link href="/visa">
              
                <div
                  className={`${styles.linkCntr} ${
                    activePage === "visa" ? styles.active : ""
                  }`}
                >
                  <FaPassport className={styles.icon} />
                  <span className={styles.linkName}>Visa</span>
                </div>
              
            </Link>
          </li>
          <li className={styles.link}>
            <Link href="/airporttransfers">
              
                <div
                  className={`${styles.linkCntr} ${
                    activePage === "airportTransfers" ? styles.active : ""
                  }`}
                >
                  <MdOutlineHail className={styles.icon} />
                  <span className={styles.linkName}>Airport Transfers</span>
                </div>
              
            </Link>
          </li>
          <li className={styles.link}>
            <Link href="/carrentals">
              
                <div
                  className={`${styles.linkCntr} ${
                    activePage === "carRentals" ? styles.active : ""
                  }`}
                >
                  <FaCar className={styles.icon} />
                  <span className={styles.linkName}>Car Rentals</span>
                </div>
              
            </Link>
          </li> */}
        </ul>
      </div>
    </nav>
  );
}

export default SideBar;
