import axios from "axios";
// import Router from "next/router";
import { baseURL, defaultHeaders } from "./axiosDefaults";

export const planeSearch = (payload) => {
  const URL = `${baseURL}/flight/offers`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    withCredentials: true,
    data: payload,
    // params: JSON.stringify(searchp),
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const confirmPrice = (payload) => {
  const URL = `${baseURL}/flight/confirm-offer`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const seatMap = (payload) => {
  const URL = `${baseURL}/flight/seat-map`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.message;
    });
};
export const chargableBags = (payload) => {
  const URL = `${baseURL}/flight/chargable-bags`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const fareRules = (payload) => {
  const URL = `${baseURL}/flight/fare-rules`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const orderFlight = (payload) => {
  const URL = `${baseURL}/flight/create-order`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const setPayment = (payload) => {
  const URL = `${baseURL}/api/flightPaymentMethod`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const popularTrips = async () => {
  const URL = `${baseURL}/flight/popular-trips`;
  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const blogs = () => {
  const URL = `https://blog.tramango.com/wp-json/wp/v2/categories`;

  return axios(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.message;
    });
};

export const checkServerStatus = async () => {
  const URL = `${baseURL}/check`;
  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      withCredentials: true,
    });
    return response;
  } catch (error) {
    throw error.data;
  }
};
