import React from "react";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Image from "next/image";
import Link from "next/link";
import logo from "../../../public/logos/tramango-logo-black.png";
import styles from "./styles.module.scss";

function Footer() {
  //* function to automatically update copyright date
  const date = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <footer className={`${styles.footer} dontPrint`}>
      <div className=" flex flex-col lg:flex-row w-full lg:space-x-12 justify-between">
        <div className=" shrink-0 max-w-[170px] mb-[25px] lg:mb-0">
          <div className={`${styles.logo} lg:mt-[-25px] `}>
            <Link href="/">
              <Image src={logo} alt="company logo" />
            </Link>
          </div>

          <div className={` flex gap-[15px] mt-[10px] `}>
            <a
              href="https://facebook.com/Tramangosocial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="text-[rgba(47,44,44,0.73)] text-[1.1rem] hover:text-[#3b5998] transition-colors ease-in-out duration-300" />
            </a>
            <a
              href="https://twitter.com/Tramangosocial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter className="text-[rgba(47,44,44,0.73)] text-[1.1rem] hover:text-[#00acee] transition-colors ease-in-out duration-300" />
            </a>
            <a
              href="https://www.instagram.com/tramangosocial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-[rgba(47,44,44,0.73)] text-[1.1rem] hover:text-[#D74263] transition-colors ease-in-out duration-300" />
            </a>

            <a
              href="https://www.linkedin.com/company/tramango/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="text-[rgba(47,44,44,0.73)] text-[1.1rem] hover:text-[#0072b1] transition-colors ease-in-out duration-300" />
            </a>
          </div>
          {/* <div className={styles.atmCards}>
              <img src="/images/visa-card.svg" alt="visa card" />
              <img src="/images/mastercard.svg" alt="mastercard" />
              <img
                src="/images/american-express.svg"
                alt="american express card"
              />
              <img src="/images/flutterwave-long.svg" alt="flutterwave" />
              <img src="/images/paystack-long.svg" alt="paystack" />
              <img src="/images/interswitch.svg" alt="interswitch" />
            </div> */}

          <div
            className={` max-w-[140px] mt-[25px] grid grid-cols-3 gap-[10px]`}
          >
            {/* <img src="/images/cards-combined.png" alt="supported payments" /> */}
            <div className=" border max-w-[40px] max-h-[25px] flex items-center justify-center rounded-[8px]">
              <img
                className=" w-full h-full"
                src="/images/Mastercard.png"
                alt="mastercard"
              />
            </div>
            <div className=" border max-w-[40px] max-h-[25px] flex items-center justify-center rounded-[8px]">
              <img
                className=" w-full h-full"
                src="/images/Visa.png"
                alt="visa card"
              />
            </div>
            <div className=" border max-w-[40px] max-h-[25px] flex items-center justify-center rounded-[8px]">
              <img
                className=" w-full h-full"
                src="/images/Verve.png"
                alt="verve card"
              />
            </div>
            <div className=" border max-w-[40px] max-h-[25px] flex items-center justify-center rounded-[8px]">
              <img
                className=" w-full h-full"
                src="/images/Americanex.png"
                alt="american express card"
              />
            </div>
            <div className=" border max-w-[40px] max-h-[25px] flex items-center justify-center rounded-[8px]">
              <img
                className=" w-full h-full"
                src="/images/Discover.png"
                alt="discover card"
              />
            </div>
            <div className=" border max-w-[40px] max-h-[25px] flex items-center justify-center rounded-[8px]">
              <img
                className=" w-full h-full"
                src="/images/Googlepay.png"
                alt="google pay"
              />
            </div>
            <div className=" border max-w-[40px] max-h-[25px] flex items-center justify-center rounded-[8px]">
              <img
                className=" w-full h-full"
                src="/images/Applepay.png"
                alt="apple pay"
              />
            </div>
          </div>
        </div>

        <div className={`${styles.pageLinks} w-full`}>
          <div className={styles.footerSect}>
            <div>
              <h3>{t("aboutTramango")}</h3>
              <a
                href="https://company.tramango.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("aboutUs")}
              </a>
              <a
                href="https://blog.tramango.com/press-release/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("pressRelease")}
              </a>
              <a
                href="https://blog.tramango.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("tramangoBlog")}
              </a>
              <a
                href="https://company.tramango.com/careers"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("careers")}
              </a>
            </div>
          </div>

          <div className={styles.footerSect}>
            <div>
              <h3>{t("partnerships")}</h3>
              <a
                href="https://company.tramango.com/partnerships/#partner"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("ourPartners")}
              </a>
              {/* <a
              href="https://company.tramango.com/influencer_program"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.links}
            >
              {t("influencerProgram")}
            </a> */}
              <a
                href="https://company.tramango.com/partnership"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("partnerwithTramango")}
              </a>
            </div>
          </div>

          <div className={styles.footerSect}>
            <div>
              <h3>{t("termsOfUse")}</h3>
              <a
                href="https://company.tramango.com/terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("termsAndConditions")}
              </a>
              <a
                href="https://company.tramango.com/Terms-of-use/#Privacy-Policy"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("privacyPolicy")}
              </a>
              <a
                href="https://company.tramango.com/Terms-of-use/#cookie-Policy"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("cookiePolicy")}
              </a>
            </div>
          </div>

          <div className={styles.footerSect}>
            <div>
              <h3>{t("help")}</h3>
              <a
                href="https://support.tramango.com"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("support")}
              </a>
              <a
                href="https://support.tramango.com"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("faq")}
              </a>
              <a
                href="https://help.tramango.com/portal/en/kb/articles/refund-timelines-processes-and-policies"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("refundTimelines")}
              </a>
              <a
                href="https://help.tramango.com/portal/en/newticket"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.links}
              >
                {t("cancelOrModifyBooking")}
              </a>
              <a
                href="/payment"
                // target="_blank"
                // rel="noopener noreferrer"
                className={styles.links}
              >
                {t("payForAReservedFlightBooking")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className={styles.copyright}>
        {t("copyright")} © {date} Tramango. {t("allRightReserved")}
      </p>
    </footer>
  );
}

export default Footer;
