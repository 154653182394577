/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import {
  getBookingOverview,
  getPersonalInfo,
  updatePersonalInfo,
  changePassword,
  // flight
  getFlightHistoryByEmail,
  getUpcomingFlightHistoryByToken,
  getPastFlightHistoryByToken,
  getCancelledFlightHistoryByToken,
  getFlightHistoryByToken,
  // insurance
  getInsuranceHistory,
  getUpcomingInsuranceHistory,
  getPastInsuranceHistory,
  getCancelledInsuranceHistory,
  getAllBookings,
  getAllFlightBookings,
  getAllInsuranceBookings,
  getABooking,
  uploadPhoto,
} from "./dashboardThunk";

const initialState = {
  isLoading: false,
  errorMessage: null,
  activePage: "overview",
  bookingOverview: null,
  personalInfo: null,
  flightHistory: null,
  upcomingFlightHistory: null,
  pastFlightHistory: null,
  cancelledFlightHistory: null,
  insuranceHistory: null,
  upcomingInsuranceHistory: null,
  pastInsuranceHistory: null,
  cancelledInsuranceHistory: null,
  allBookings: null,
  allFlightBookings: null,
  allInsuranceBookings: null,
  selectedBooking: null,
  uploaded: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
  },
  extraReducers: {
    [getBookingOverview.pending]: (state) => {
      state.isLoading = true;
    },
    [getBookingOverview.fulfilled]: (state, action) => {
      state.bookingOverview = action.payload;
      state.isLoading = false;
    },
    [getBookingOverview.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        const { message } = action.payload.message;
        state.errorMessage = message;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getPersonalInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [getPersonalInfo.fulfilled]: (state, action) => {
      state.personalInfo = action.payload;
      state.isLoading = false;
    },
    [getPersonalInfo.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        const { message } = action.payload.message;
        state.errorMessage = message;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [updatePersonalInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [updatePersonalInfo.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success("Updated successfully");
    },
    [updatePersonalInfo.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },

    [changePassword.pending]: (state) => {
      state.isLoading = true;
    },
    [changePassword.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success("Password changed successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [changePassword.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        const { error } = action.payload;
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Could not change password", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },

    [getFlightHistoryByEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [getFlightHistoryByEmail.fulfilled]: (state, action) => {
      state.flightHistory = action.payload;
      state.isLoading = false;
    },
    [getFlightHistoryByEmail.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getFlightHistoryByToken.pending]: (state) => {
      state.isLoading = true;
    },
    [getFlightHistoryByToken.fulfilled]: (state, action) => {
      state.flightHistory = action.payload;
      state.isLoading = false;
    },
    [getFlightHistoryByToken.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getUpcomingFlightHistoryByToken.pending]: (state) => {
      state.isLoading = true;
    },
    [getUpcomingFlightHistoryByToken.fulfilled]: (state, action) => {
      state.upcomingFlightHistory = action.payload;
      state.isLoading = false;
    },
    [getUpcomingFlightHistoryByToken.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getPastFlightHistoryByToken.pending]: (state) => {
      state.isLoading = true;
    },
    [getPastFlightHistoryByToken.fulfilled]: (state, action) => {
      state.pastFlightHistory = action.payload;
      state.isLoading = false;
    },
    [getPastFlightHistoryByToken.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getCancelledFlightHistoryByToken.pending]: (state) => {
      state.isLoading = true;
    },
    [getCancelledFlightHistoryByToken.fulfilled]: (state, action) => {
      state.cancelledFlightHistory = action.payload;
      state.isLoading = false;
    },
    [getCancelledFlightHistoryByToken.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getInsuranceHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [getInsuranceHistory.fulfilled]: (state, action) => {
      state.insuranceHistory = action.payload;
      state.isLoading = false;
    },
    [getInsuranceHistory.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getUpcomingInsuranceHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [getUpcomingInsuranceHistory.fulfilled]: (state, action) => {
      state.upcomingInsuranceHistory = action.payload;
      state.isLoading = false;
    },
    [getUpcomingInsuranceHistory.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getPastInsuranceHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [getPastInsuranceHistory.fulfilled]: (state, action) => {
      state.pastInsuranceHistory = action.payload;
      state.isLoading = false;
    },
    [getPastInsuranceHistory.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getCancelledInsuranceHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [getCancelledInsuranceHistory.fulfilled]: (state, action) => {
      state.cancelledInsuranceHistory = action.payload;
      state.isLoading = false;
    },
    [getCancelledInsuranceHistory.rejected]: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload;
      } else {
        state.errorMessage = "Error processing request";
      }
    },

    [getAllBookings.fulfilled]: (state, action) => {
      state.allBookings = action.payload;
      state.isLoading = false;
    },
    [getAllBookings.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },

    [getAllBookings.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllFlightBookings.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllFlightBookings.fulfilled]: (state, action) => {
      state.allFlightBookings = action.payload;
      state.isLoading = false;
    },
    [getAllFlightBookings.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },

    [getAllInsuranceBookings.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllInsuranceBookings.fulfilled]: (state, action) => {
      state.allInsuranceBookings = action.payload;
      state.isLoading = false;
    },
    [getAllInsuranceBookings.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getABooking.pending]: (state) => {
      state.isLoading = true;
    },
    [getABooking.fulfilled]: (state, action) => {
      state.selectedBooking = action.payload;
      state.isLoading = false;
    },
    [getABooking.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [uploadPhoto.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadPhoto.fulfilled]: (state, action) => {
      state.uploaded = action.payload;
      state.isLoading = false;
    },
    [uploadPhoto.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  },
});
export const { setActivePage } = dashboardSlice.actions;

export default dashboardSlice.reducer;
