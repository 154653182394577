import Image from "next/image";
import Link from "next/link";
import React from "react";
// import PropTypes from "prop-types";

function Maintenance() {
  // const { status, isLoading } = store.getState().auth;
  // if (status !== true && !isLoading) {
  //   return children;
  // }
  // if (isLoading) {
  //   return <GeneralLoading />;
  // }
  return (
    <div className=" h-screen">
      <div className=" pb-[64px] p-[24px] w-full mx-auto max-w-[960px] ">
        <div className=" m-0 p-0 ">
          <div className=" mt-[24px]">
            <div className=" gap-[40px] flex flex-col items-center w-full ">
              <Image
                height={303}
                width={303}
                className=" inline-block max-h-[120px] max-w-full bg-transparent "
                src="/Maintenance-bro.svg"
              />
              <div className=" flex w-full justify-center">
                <div>
                  <h2 className=" text-[22px] font-semibold mb-[8px] leading-[28px] text-center  text-[rgb(37,42,49)] ">
                    Tramango is currently undergoing maintenance
                  </h2>
                  <div className=" mb-[24px]  ">
                    <div className=" text-[16px] text-[rgb(79,94,113)] font-normal text-center  leading[24px]">
                      Please bear with us
                    </div>
                  </div>
                  <div className=" gap-4 flex justify-center items-start w-full ">
                    <div className=" cursor-pointer w-auto ">
                      <Link href="/insurance">
                        <div className=" h-[44px] rounded-[3px] px-4 inline-flex justify-between items-center text-center max-w-full bg-tramangoOrange mb-[24px] w-auto text-[15px] font-medium ">
                          <div className=" align-middle h-full flex justify-between items-center">
                            <div className=" text-white flex items-center">
                              Contact Support
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;

// Maintenance.propTypes = {
//   children: PropTypes.node.isRequired,
// };
