import { createAsyncThunk } from "@reduxjs/toolkit";
import Router from "next/router";
import * as api from "../../services/flightAPI";

export const PlaneSearch = createAsyncThunk(
  "SEARCH FLIGHTS",
  async (payload) => {
    try {
      const response = await api.planeSearch(payload);
      return response;
    } catch (error) {
      throw error.response.data.amadeus_code;
    }
  }
);

export const ConfirmPrice = createAsyncThunk(
  "CONFIRM_PRICE",
  async (payload) => {
    try {
      const response = await api.confirmPrice(payload);
      return response;
    } catch (error) {
      throw error.response.data.amadeus_code;
    }
  }
);

export const Seatmap = createAsyncThunk("GET_SEAT_MAP", async (payload) => {
  const response = await api.seatMap(payload);
  // Router.push(`/flights/travelersinfo`);
  return response;
});

export const GetChargableBags = createAsyncThunk(
  "GET_CHARGABLE_BAGS",
  async (payload) => {
    const response = await api.chargableBags(payload);
    // Router.push(`/flights/travelersinfo`);
    return response;
  }
);

export const FareRules = createAsyncThunk("GET_FARE_RULES", async (payload) => {
  const response = await api.fareRules(payload);
  // Router.push(`/flights/travelersinfo`);
  return response;
});

export const OrderFlight = createAsyncThunk("ORDER_FLIGHT", async (payload) => {
  try {
    const response = await api.orderFlight(payload);
    return response;
  } catch (error) {
    throw error.response.data.amadeus_code;
  }
});
export const setFlightPayment = createAsyncThunk(
  "FLIGHT_PAYMENT",
  async (payload) => {
    const response = await api.setPayment(payload);
    Router.push("/flights/confirmation");
    return response;
  }
);

// export const getPopularTrips = createAsyncThunk(
//   "FETCH POPULAR TRIPS",
//   async (payload, { rejectWithValue }) => {
//     try {
//       const response = await api.popularTrips(payload);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const getPopularTrips = createAsyncThunk(
  "FETCH POPULAR TRIPS",
  async (payload) => {
    const response = await api.popularTrips(payload);
    return response;
  }
);

export const getBlogs = createAsyncThunk("FETCH BLOG POST", async (payload) => {
  const response = await api.blogs(payload);
  return response;
});

export const checkServerStatus = createAsyncThunk(
  "CHECK SERVER STATUS",
  async () => {
    try {
      const response = await api.checkServerStatus();
      return response;
    } catch (error) {
      return error;
    }
  }
);
