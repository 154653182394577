import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/authAPI";

export const Login = createAsyncThunk("LOG IN", async (payload) => {
  const response = await api.Login(payload);
  window.location.reload();
  return response;
});

export const checkSystem = createAsyncThunk("SYSTEM CHECK", async () => {
  const response = await api.checkSystem();
  return response;
});

export const Subscribe = createAsyncThunk("SUBSCRIBE", async (payload) => {
  const response = await api.Subscribe(payload);
  return response;
});

export const startResetPassword = createAsyncThunk(
  "START RESET PASSWORD",
  async (payload) => {
    const response = await api.StartResetPassword(payload);
    return response;
  }
);
export const confirmResetPassword = createAsyncThunk(
  "CONFIRM RESET PASSWORD",
  async (payload) => {
    const response = await api.ConfirmResetPassword(payload);
    return response;
  }
);
export const ResetPassword = createAsyncThunk(
  "RESET PASSWORD",
  async (payload) => {
    const response = await api.ResetPassword(payload);
    return response;
  }
);
export const GoogleLogin = createAsyncThunk(
  "GOOGLE LOG IN",
  async (payload) => {
    const response = await api.LoginGoogle(payload);
    // Router.push("/");
    return response;
  }
);
export const LogOut = createAsyncThunk("LOG OUT", async (payload) => {
  const response = await api.LogOut(payload);
  localStorage.removeItem("user");
  return response;
});

export const Register = createAsyncThunk("REGISTER", async (payload) => {
  const response = await api.Register(payload);
  return response;
});

export const VerifyEmail = createAsyncThunk("VERIFY EMAIL", async (payload) => {
  const response = await api.VerifyEmail(payload);
  return response;
});

export const getUserData = createAsyncThunk("FETCH_USER_DATA", async () => {
  const response = await api.getUserData();
  return response;
});

export const updateUserData = createAsyncThunk(
  "UPDATE USER DATA",
  async (payload) => {
    const response = await api.updateUserProfileData(payload);
    return response;
  }
);

export const checkCookie = createAsyncThunk("CHECK_COOKIE", async () => {
  const response = await api.Cookie();
  return response;
});
export const getLocationData = createAsyncThunk(
  "FETCH_LOCATION_DATA",
  async () => {
    const response = await api.getLocationData();
    return response;
  }
);

export const forgotPassword = createAsyncThunk(
  "FORGOT PASSWORD",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.forgotPassword(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "VERIFY OTP",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.verifyOtp(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "UPDATE PASSWORD",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.updatePassword(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
