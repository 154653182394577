import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import ErrorImage from "../../public/images/no-connection-illustration.png";
import ErrorCard from "./ErrorCard";

function NoInternetConnection({ children }) {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  if (typeof window !== "undefined") {
    window.addEventListener("online", () => {
      setOnline(true);
    });
  }

  if (typeof window !== "undefined") {
    window.addEventListener("offline", () => {
      setOnline(false);
    });
  }

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return children;
  }
  return (
    <ErrorCard
      image="https://tramangoimages.s3.us-east-1.amazonaws.com/tramango_image_9051adcc-cabf-42e1-9311-42a1945bce86_illustration.svg"
      title="Hey, you are not connected to the internet."
      subtitle="Please reconnect and try again."
    />
  );
}

NoInternetConnection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NoInternetConnection;
