import axios from "axios";
import { baseURL, defaultHeaders, setAuthHeaders } from "./axiosDefaults";

export const getBookingOverview = async (payload) => {
  const URL = `${baseURL}/api/v1/userBookingOverview?type=all&baseCode=${payload}`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};
export const getPersonalInfo = async (payload) => {
  const URL = `${baseURL}/api/v1/getPersonalInfo`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const updatePersonalInfo = async (payload) => {
  const URL = `${baseURL}/api/v1/personalInfo`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const changePassword = async (payload) => {
  const URL = `${baseURL}/api/v1/change_password`;

  try {
    const response = await axios(URL, {
      method: "POST",

      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getFlightHistoryByEmail = async (payload) => {
  const URL = `${baseURL}/api/flightHistory?email=${payload}`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getFlightHistoryByToken = async (payload) => {
  const URL = `${baseURL}/api/flightHistory`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getUpcomingFlightHistoryByToken = async (payload) => {
  const URL = `${baseURL}/api/v1/tripLists?period=upcoming&product_type=flight`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPastFlightHistoryByToken = async (payload) => {
  const URL = `${baseURL}/api/v1/tripLists?period=past&product_type=flight`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getCancelledFlightHistoryByToken = async (payload) => {
  const URL = `${baseURL}/api/v1/tripLists?period=cancelled&product_type=flight`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getInsuranceHistory = async (payload) => {
  const URL = `${baseURL}/api/v1/getUserInsurance`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getUpcomingInsuranceHistory = async (payload) => {
  const URL = `${baseURL}/api/v1/tripLists?period=upcoming&product_type=insurance`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPastInsuranceHistory = async (payload) => {
  const URL = `${baseURL}/api/v1/tripLists?period=past&product_type=insurance`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getCancelledInsuranceHistory = async (payload) => {
  const URL = `${baseURL}/api/v1/tripLists?period=cancelled&product_type=insurance`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const uploadUserPhoto = (payload) => {
  const { userType, userId } = payload;
  const URL = `${baseURL}/users/${userType}/${userId}/photo`;
  return axios(URL, {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const deleteAccount = (payload) => {
  const { userType, userId } = payload;
  const URL = `${baseURL}/users/${userType}/${userId}/photo`;
  return axios(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    withCredentials: true,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

// export const getAllFlightBookings = async (payload) => {
//   const URL = `${baseURL}/bookings/flight`;
//   try {
//     const response = await axios(URL, {
//       method: "GET",
//       headers: await setAuthHeaders(defaultHeaders),
//       data: payload,
//       withCredentials: true,
//     });

//     return response.data;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

export const getAllFlightBookings = async ({ filter, page }) => {
  const URL = `${baseURL}/bookings/flight`;

  try {
    const response = await axios.get(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      params: { filter, page }, // Include query parameters here
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAllInsuranceBookings = async (payload) => {
  const URL = `${baseURL}/bookings/insurance`;
  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getABooking = async (payload) => {
  const { bookingId } = payload;
  const URL = `${baseURL}/bookings/${bookingId}}`;
  try {
    const response = await axios(URL, {
      method: "GET",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
