import { useState, useEffect, useRef } from "react";

export function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);

      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

export function useClassSelector(initialIsVisible) {
  const [isClassVisible, setIsClassVisible] = useState(initialIsVisible);
  const classref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsClassVisible(false);
    }
  };
  const handleClickOutside = (event) => {
    if (classref.current && !classref.current.contains(event.target)) {
      setIsClassVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);

      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { classref, isClassVisible, setIsClassVisible };
}

export function useDepartureVisible(initialIsVisible) {
  const [isDepartureVisible, setIsDepartureVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsDepartureVisible(false);
    }
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsDepartureVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);

      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isDepartureVisible, setIsDepartureVisible };
}

export function useArrivalVisible(initialIsVisible) {
  const [isArrivalVisible, setIsArrivalVisible] = useState(initialIsVisible);
  const ref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsArrivalVisible(false);
    }
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsArrivalVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);

      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isArrivalVisible, setIsArrivalVisible };
}

export function useCurrencyVisible(initialIsVisible) {
  const [isCurrencyVisible, setIsCurrencyVisible] = useState(initialIsVisible);
  const ref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsCurrencyVisible(false);
    }
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsCurrencyVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);

      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isCurrencyVisible, setIsCurrencyVisible };
}

export function useLanguageVisible(initialIsVisible) {
  const [isLanguageVisible, setIsLanguageVisible] = useState(initialIsVisible);
  const langref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsLanguageVisible(false);
    }
  };
  const handleClickOutside = (event) => {
    if (langref.current && !langref.current.contains(event.target)) {
      setIsLanguageVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);

      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { langref, isLanguageVisible, setIsLanguageVisible };
}

export function useSideBarVisible(initialIsVisible) {
  const [isSideBarVisible, setIsSideBarVisible] = useState(initialIsVisible);
  const sidebarref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsSideBarVisible(false);
    }
  };
  const handleClickOutside = (event) => {
    if (sidebarref.current && !sidebarref.current.contains(event.target)) {
      setIsSideBarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);

      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { sidebarref, isSideBarVisible, setIsSideBarVisible };
}

export function useCityVisible(initialIsVisible) {
  const [isCityVisible, setIsCityVisible] = useState(initialIsVisible);
  const ref = useRef(null);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsCityVisible(false);
    }
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsCityVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);

      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isCityVisible, setIsCityVisible };
}
