/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import Router from "next/router";
import { BookFamilyPlan } from "./insuranceThunk";

const initialState = {
  DataFamily: null,
  loading: false,
  insuranceFamilyLoader: false,
  familyBookingIds: null,
  message: "",
  // general info
  FamilyLastName: "",
  FamilyState: "",
  FamilyAddress: "",
  FamilyZipCode: "",

  // next of kin
  FamilyKinName: "",
  FamilyKinShip: "",
  FamilyKinAddress: "",
  FamilyKinPhone: "",

  // parent 1
  ParentTitle1: "",
  ParentFirstName1: "",
  ParentMiddleName1: "",
  ParentDOB1: "",
  ParentGender1: "",
  ParentPassport1: "",
  ParentEmail1: "",
  ParentPhone1: "",
  ParentOccupation1: "",
  ParentMaritalStatus1: "",
  ParentAnyCondition1: "",
  ParentMedicalCondition1: "",

  // parent 2
  ParentTitle2: "",
  ParentFirstName2: "",
  ParentMiddleName2: "",
  ParentDOB2: "",
  ParentGender2: "",
  ParentPassport2: "",
  ParentEmail2: "",
  ParentPhone2: "",
  ParentOccupation2: "",
  ParentMaritalStatus2: "",
  ParentAnyCondition2: "",
  ParentMedicalCondition2: "",

  // child1
  ChildTitle1: "",
  ChildFirstName1: "",
  ChildMiddleName1: "",
  ChildDOB1: "",
  ChildGender1: "",
  ChildPassport1: "",
  ChildAnyCondition1: "",
  ChildMedicalCondition1: "",

  // child2
  ChildTitle2: "",
  ChildFirstName2: "",
  ChildMiddleName2: "",
  ChildDOB2: "",
  ChildGender2: "",
  ChildPassport2: "",
  ChildAnyCondition2: "",
  ChildMedicalCondition2: "",

  // child3
  ChildTitle3: "",
  ChildFirstName3: "",
  ChildMiddleName3: "",
  ChildDOB3: "",
  ChildGender3: "",
  ChildPassport3: "",
  ChildAnyCondition3: "",
  ChildMedicalCondition3: "",

  // child4
  ChildTitle4: "",
  ChildFirstName4: "",
  ChildMiddleName4: "",
  ChildDOB4: "",
  ChildGender4: "",
  ChildPassport4: "",
  ChildAnyCondition4: "",
  ChildMedicalCondition4: "",

  // child5
  ChildTitle5: "",
  ChildFirstName5: "",
  ChildMiddleName5: "",
  ChildDOB5: "",
  ChildGender5: "",
  ChildPassport5: "",
  ChildAnyCondition5: "",
  ChildMedicalCondition5: "",

  // child6
  ChildTitle6: "",
  ChildFirstName6: "",
  ChildMiddleName6: "",
  ChildDOB6: "",
  ChildGender6: "",
  ChildPassport6: "",
  ChildAnyCondition6: "",
  ChildMedicalCondition6: "",
};

export const familySlice = createSlice({
  name: "family",
  initialState,
  reducers: {
    // general info
    setDataFamily: (state, action) => {
      state.DataFamily = action.payload;
    },
    setFamilyLastName: (state, action) => {
      state.FamilyLastName = action.payload;
    },
    setFamilyState: (state, action) => {
      state.FamilyState = action.payload;
    },
    setFamilyAddress: (state, action) => {
      state.FamilyAddress = action.payload;
    },
    setFamilyZipCode: (state, action) => {
      state.FamilyZipCode = action.payload;
    },
    // next of kin
    setFamilyKinName: (state, action) => {
      state.FamilyKinName = action.payload;
    },
    setFamilyKinShip: (state, action) => {
      state.FamilyKinShip = action.payload;
    },
    setFamilyKinAddress: (state, action) => {
      state.FamilyKinAddress = action.payload;
    },
    setFamilyKinPhone: (state, action) => {
      state.FamilyKinPhone = action.payload;
    },
    // parent1
    setParentTitle1: (state, action) => {
      state.ParentTitle1 = action.payload;
    },
    setParentFirstName1: (state, action) => {
      state.ParentFirstName1 = action.payload;
    },
    setParentMiddleName1: (state, action) => {
      state.ParentMiddleName1 = action.payload;
    },
    setParentDOB1: (state, action) => {
      state.ParentDOB1 = action.payload;
    },
    setParentGender1: (state, action) => {
      state.ParentGender1 = action.payload;
    },
    setParentPhone1: (state, action) => {
      state.ParentPhone1 = action.payload;
    },
    setParentEmail1: (state, action) => {
      state.ParentEmail1 = action.payload;
    },
    setParentOccupation1: (state, action) => {
      state.ParentOccupation1 = action.payload;
    },
    setParentMaritalStatus1: (state, action) => {
      state.ParentMaritalStatus1 = action.payload;
    },
    setParentAnyCondition1: (state, action) => {
      state.ParentAnyCondition1 = action.payload;
    },
    setParentMedicalCondition1: (state, action) => {
      state.ParentMedicalCondition1 = action.payload;
    },
    setParentPassport1: (state, action) => {
      state.ParentPassport1 = action.payload;
    },
    // parent 2
    setParentTitle2: (state, action) => {
      state.ParentTitle2 = action.payload;
    },
    setParentFirstName2: (state, action) => {
      state.ParentFirstName2 = action.payload;
    },
    setParentMiddleName2: (state, action) => {
      state.ParentMiddleName2 = action.payload;
    },
    setParentDOB2: (state, action) => {
      state.ParentDOB2 = action.payload;
    },
    setParentGender2: (state, action) => {
      state.ParentGender2 = action.payload;
    },
    setParentPhone2: (state, action) => {
      state.ParentPhone2 = action.payload;
    },
    setParentEmail2: (state, action) => {
      state.ParentEmail2 = action.payload;
    },
    setParentOccupation2: (state, action) => {
      state.ParentOccupation2 = action.payload;
    },
    setParentMaritalStatus2: (state, action) => {
      state.ParentMaritalStatus2 = action.payload;
    },
    setParentAnyCondition2: (state, action) => {
      state.ParentAnyCondition2 = action.payload;
    },
    setParentMedicalCondition2: (state, action) => {
      state.ParentMedicalCondition2 = action.payload;
    },
    setParentPassport2: (state, action) => {
      state.ParentPassport2 = action.payload;
    },
    // child 1
    setChildTitle1: (state, action) => {
      state.ChildTitle1 = action.payload;
    },
    setChildFirstName1: (state, action) => {
      state.ChildFirstName1 = action.payload;
    },
    setChildMiddleName1: (state, action) => {
      state.ChildMiddleName1 = action.payload;
    },
    setChildDOB1: (state, action) => {
      state.ChildDOB1 = action.payload;
    },
    setChildGender1: (state, action) => {
      state.ChildGender1 = action.payload;
    },
    setChildAnyCondition1: (state, action) => {
      state.ChildAnyCondition1 = action.payload;
    },
    setChildMedicalCondition1: (state, action) => {
      state.ChildMedicalCondition1 = action.payload;
    },
    setChildPassport1: (state, action) => {
      state.ChildPassport1 = action.payload;
    },
    // child 2
    setChildTitle2: (state, action) => {
      state.ChildTitle2 = action.payload;
    },
    setChildFirstName2: (state, action) => {
      state.ChildFirstName2 = action.payload;
    },
    setChildMiddleName2: (state, action) => {
      state.ChildMiddleName2 = action.payload;
    },
    setChildDOB2: (state, action) => {
      state.ChildDOB2 = action.payload;
    },
    setChildGender2: (state, action) => {
      state.ChildGender2 = action.payload;
    },
    setChildAnyCondition2: (state, action) => {
      state.ChildAnyCondition2 = action.payload;
    },
    setChildMedicalCondition2: (state, action) => {
      state.ChildMedicalCondition2 = action.payload;
    },
    setChildPassport2: (state, action) => {
      state.ChildPassport2 = action.payload;
    },
    // child 3
    setChildTitle3: (state, action) => {
      state.ChildTitle3 = action.payload;
    },
    setChildFirstName3: (state, action) => {
      state.ChildFirstName3 = action.payload;
    },
    setChildMiddleName3: (state, action) => {
      state.ChildMiddleName3 = action.payload;
    },
    setChildDOB3: (state, action) => {
      state.ChildDOB3 = action.payload;
    },
    setChildGender3: (state, action) => {
      state.ChildGender3 = action.payload;
    },
    setChildAnyCondition3: (state, action) => {
      state.ChildAnyCondition3 = action.payload;
    },
    setChildMedicalCondition3: (state, action) => {
      state.ChildMedicalCondition3 = action.payload;
    },
    setChildPassport3: (state, action) => {
      state.ChildPassport3 = action.payload;
    },
    // child 4
    setChildTitle4: (state, action) => {
      state.ChildTitle4 = action.payload;
    },
    setChildFirstName4: (state, action) => {
      state.ChildFirstName4 = action.payload;
    },
    setChildMiddleName4: (state, action) => {
      state.ChildMiddleName4 = action.payload;
    },
    setChildDOB4: (state, action) => {
      state.ChildDOB4 = action.payload;
    },
    setChildGender4: (state, action) => {
      state.ChildGender4 = action.payload;
    },
    setChildAnyCondition4: (state, action) => {
      state.ChildAnyCondition4 = action.payload;
    },
    setChildMedicalCondition4: (state, action) => {
      state.ChildMedicalCondition4 = action.payload;
    },
    setChildPassport4: (state, action) => {
      state.ChildPassport4 = action.payload;
    },
    // child 5
    setChildTitle5: (state, action) => {
      state.ChildTitle5 = action.payload;
    },
    setChildFirstName5: (state, action) => {
      state.ChildFirstName5 = action.payload;
    },
    setChildMiddleName5: (state, action) => {
      state.ChildMiddleName5 = action.payload;
    },
    setChildDOB5: (state, action) => {
      state.ChildDOB5 = action.payload;
    },
    setChildGender5: (state, action) => {
      state.ChildGender5 = action.payload;
    },
    setChildAnyCondition5: (state, action) => {
      state.ChildAnyCondition5 = action.payload;
    },
    setChildMedicalCondition5: (state, action) => {
      state.ChildMedicalCondition5 = action.payload;
    },
    setChildPassport5: (state, action) => {
      state.ChildPassport5 = action.payload;
    },
    // child 6
    setChildTitle6: (state, action) => {
      state.ChildTitle6 = action.payload;
    },
    setChildFirstName6: (state, action) => {
      state.ChildFirstName6 = action.payload;
    },
    setChildMiddleName6: (state, action) => {
      state.ChildMiddleName6 = action.payload;
    },
    setChildDOB6: (state, action) => {
      state.ChildDOB6 = action.payload;
    },
    setChildGender6: (state, action) => {
      state.ChildGender6 = action.payload;
    },
    setChildAnyCondition6: (state, action) => {
      state.ChildAnyCondition6 = action.payload;
    },
    setChildMedicalCondition6: (state, action) => {
      state.ChildMedicalCondition6 = action.payload;
    },
    setChildPassport6: (state, action) => {
      state.ChildPassport6 = action.payload;
    },
  },
  extraReducers: {
    [BookFamilyPlan.pending]: (state) => {
      state.insuranceFamilyLoader = true;
    },
    [BookFamilyPlan.fulfilled]: (state, action) => {
      state.familyBookingIds = action.payload;
      Router.push("/insurance/confirmation");
      state.insuranceFamilyLoader = false;
    },
    [BookFamilyPlan.rejected]: (state) => {
      Router.push("/insurance/error");
      state.insuranceFamilyLoader = false;
      toast.error("Error Processing request", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  },
});
export const {
  // general info
  setDataFamily,
  setFamilyLastName,
  setFamilyState,
  setFamilyAddress,
  setFamilyZipCode,
  // next of kin
  setFamilyKinName,
  setFamilyKinShip,
  setFamilyKinAddress,
  setFamilyKinPhone,
  // parent 1
  setParentTitle1,
  setParentFirstName1,
  setParentMiddleName1,
  setParentDOB1,
  setParentGender1,
  setParentPhone1,
  setParentEmail1,
  setParentOccupation1,
  setParentMaritalStatus1,
  setParentAnyCondition1,
  setParentMedicalCondition1,
  setParentPassport1,
  // parent 2
  setParentTitle2,
  setParentFirstName2,
  setParentMiddleName2,
  setParentDOB2,
  setParentGender2,
  setParentPhone2,
  setParentEmail2,
  setParentOccupation2,
  setParentMaritalStatus2,
  setParentAnyCondition2,
  setParentMedicalCondition2,
  setParentPassport2,
  // child 1
  setChildTitle1,
  setChildFirstName1,
  setChildMiddleName1,
  setChildDOB1,
  setChildGender1,
  setChildAnyCondition1,
  setChildMedicalCondition1,
  setChildPassport1,
  // child 2
  setChildTitle2,
  setChildFirstName2,
  setChildMiddleName2,
  setChildDOB2,
  setChildGender2,
  setChildAnyCondition2,
  setChildMedicalCondition2,
  setChildPassport2,
  // child 3
  setChildTitle3,
  setChildFirstName3,
  setChildMiddleName3,
  setChildDOB3,
  setChildGender3,
  setChildAnyCondition3,
  setChildMedicalCondition3,
  setChildPassport3,
  // child 4
  setChildTitle4,
  setChildFirstName4,
  setChildMiddleName4,
  setChildDOB4,
  setChildGender4,
  setChildAnyCondition4,
  setChildMedicalCondition4,
  setChildPassport4,
  // child 5
  setChildTitle5,
  setChildFirstName5,
  setChildMiddleName5,
  setChildDOB5,
  setChildGender5,
  setChildAnyCondition5,
  setChildMedicalCondition5,
  setChildPassport5,
  // child 6
  setChildTitle6,
  setChildFirstName6,
  setChildMiddleName6,
  setChildDOB6,
  setChildGender6,
  setChildAnyCondition6,
  setChildMedicalCondition6,
  setChildPassport6,
} = familySlice.actions;

export default familySlice.reducer;
