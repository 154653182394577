import { createAsyncThunk } from "@reduxjs/toolkit";
import Router from "next/router";
import * as api from "../../services/staysAPI";

export const staySearch = createAsyncThunk("SEARCH STAYS", async (payload) => {
  const response = await api.staySearch(payload);
  return response;
});

export const stayViaID = createAsyncThunk("STAYS VIA ID", async (payload) => {
  const response = await api.getStayviaID(payload);
  return response;
});

export const bookStay = createAsyncThunk("BOOK STAY", async (payload) => {
  const response = await api.bookStay(payload);
  Router.push("/stays/confirmation?provider=stays");

  return response;
});

export const bookStayHotelsPro = createAsyncThunk(
  "BOOK_STAY_HOTELS_PRO",
  async (payload) => {
    const response = await api.bookStayHotelsPro(payload);
    return response;
  }
);

export const bookStayAmadeus = createAsyncThunk(
  "BOOK_STAY_AMADEUS",
  async (payload) => {
    const response = await api.bookStayAmadeus(payload);
    Router.push("/stays/confirmation?provider=Amadeus");

    return response;
  }
);

export const stayPerCity = createAsyncThunk(
  "STAY PER CITY",
  async (payload) => {
    const response = await api.getStayPerCity(payload);
    return response;
  }
);

export const stayPrice = createAsyncThunk("STAY PRICE", async (payload) => {
  const response = await api.stayPriceConfirm(payload);
  return response;
});

export const getListingReviews = createAsyncThunk(
  "GET STAYS LISTING REVIEWS",
  async (payload) => {
    const response = await api.getListingReviews(payload);
    return response;
  }
);

export const getListingReviewsNegative = createAsyncThunk(
  "GET STAYS NEGATIVE LISTING REVIEWS",
  async (payload) => {
    const response = await api.getListingReviewsNegative(payload);
    return response;
  }
);

export const getListingReviewsPositive = createAsyncThunk(
  "GET STAYS POSITIVE LISTING REVIEWS ",
  async (payload) => {
    const response = await api.getListingReviewsPositive(payload);
    return response;
  }
);

export const submitUserRatings = createAsyncThunk(
  "SUBMIT USER REVIEW FOR STAYS",
  async (payload) => {
    const response = await api.rateStays(payload);
    return response;
  }
);
