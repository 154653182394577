import Image from "next/image";
import LoadingGif from "../../public/logos/mango-loader.gif";
import styles from "./styles.module.scss";

function GeneralLoading() {
  return (
    <div className={`${styles.isLoading}`}>
      <div className="">
        <Image
          src={LoadingGif}
          alt="loading"
          className="object-contain"
          width={100}
          height={100}
        />
      </div>
    </div>
  );
}

export default GeneralLoading;
