"use client";

import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
// import Marquee from "react-marquee-slider";
// import times from "lodash/times";
// import { isMobile } from "react-device-detect";
import GeneralLoading from "../../common/GeneralLoading";
import NavBar from "../../components/navigation/NavBar";
import NavSpace from "../../components/navigation/NavSpace";
import SideBar from "../../components/navigation/SideBar";
import Footer from "../../components/navigation/Footer";
import CookieConsent from "../../components/cookieConsent";
import CookieCustomize from "../../components/cookieConsent/cookieCustomize";
import LoginFormModal from "../../components/auth/LoginFormModal";
import LoginModal from "../../components/auth/loginModal";
import SignupFormModal from "../../components/auth/SignupFormModal";
import ErrorPage from "../../common/ErrorPage";

// import DescriptiveError from "../../common/DescriptiveError";

export default function MainLayout({ children }) {
  const { insuranceGetQuoteLoader } = useSelector((state) => state.getQuote);
  const { insuranceFamilyLoader } = useSelector((state) => state.family);
  const { paymentLoader } = useSelector((state) => state.payment);
  const { error, amadeusError, confirmationLoader, orderLoader, flightLoader } =
    useSelector((state) => state.flight);
  // const { error } = useSelector((state) => state.flight);

  const { AuthModal, LoginFormM, SiginupFormM, user } = useSelector(
    (state) => state.auth
  );

  const { insuranceIndividualLoader } = useSelector(
    (state) => state.individual
  );
  const [showCustomize, setShowCustomize] = useState(false);
  // eslint-disable-next-line valid-typeof
  if (
    error &&
    (amadeusError === null || amadeusError?.message === "Rejected") &&
    !confirmationLoader &&
    !orderLoader &&
    !flightLoader
  ) {
    return <ErrorPage />;
  }
  // const messages = [
  //   { id: 1, message: "Book with confidence" },
  //   { id: 2, message: "Best prices guaranteed" },
  //   { id: 3, message: "24/7 customer support" },
  //   { id: 4, message: "Maintenance on 30th July" },
  //   { id: 5, message: "Launch on 5th March" },
  //   { id: 6, message: "Tramango Raises $1.5M Pre-Seed Funding" },
  // ];

  return (
    <>
      {insuranceGetQuoteLoader ||
      insuranceFamilyLoader ||
      paymentLoader ||
      insuranceIndividualLoader ? (
        <GeneralLoading />
      ) : null}
      <div>
        <NavBar />

        <div className="wrapper">
          {/* {error && <DescriptiveError />} */}
          {AuthModal && !user && <LoginModal />}
          {LoginFormM && !user && <LoginFormModal />}
          {SiginupFormM && !user && <SignupFormModal />}
          <NavSpace />
          {/* <div className="bg-black text-white py-1.5 flex items-center z-200 ">
            <Marquee key={1} velocity={!isMobile ? 45 : 5}>
              {messages.map((message) =>
                times(1, Number).map((innerIndex) => (
                  <div key={innerIndex} className="">
                    <div
                      className="marqueeText"
                      style={{ marginRight: "130px" }}
                    >
                      {message.message}
                    </div>
                  </div>
                ))
              )}
            </Marquee>
          </div> */}
          <div className="bottom">
            <div className="left z-30">
              <SideBar />
            </div>
            <div className="right">
              {children}
              <Footer />
            </div>
            <CookieConsent setShowCustomize={setShowCustomize} />

            {showCustomize && (
              <CookieCustomize setShowCustomize={setShowCustomize} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
