import axios from "axios";
import { baseURL, defaultHeaders, setAuthHeaders } from "./axiosDefaults";

export const getFrontPageAd = async () => {
  const URL = `${baseURL}/ad/today`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: await setAuthHeaders(defaultHeaders),
      data: { position: "FRONT_PAGE" },
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const getDashboardAd = async () => {
  const URL = `${baseURL}/ad/today`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: await setAuthHeaders(defaultHeaders),
      data: { position: "DASHBOARD" },
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const getBookingConfirmationAd = async () => {
  const URL = `${baseURL}/ad/today`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: await setAuthHeaders(defaultHeaders),
      data: { position: "BOOKING_CONFIRMATION" },
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const getFlightResultsAd = async () => {
  const URL = `${baseURL}/ad/today`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: await setAuthHeaders(defaultHeaders),
      data: { position: "FLIGHT_RESULTS" },
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};
