"use client";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "../styles/globals.scss";
import "../styles/font-sizing.scss";
import "../styles/package-overrides.scss";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import Script from "next/script";
import { I18nextProvider } from "react-i18next";
import OneSignal from "react-onesignal";
import { v4 as uuidv4 } from "uuid";
import {
  getUserData,
  getLocationData,
  checkSystem,
} from "../features/auth/authThunk";
// import { setAgent } from "../features/auth/authSlice";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "../common/infoMessages/ErrorBoundary";
import NoInternetConnection from "../common/infoMessages/NoInternetConnection";
// import Countdown from "../common/infoMessages/Countdown";
import Maintenance from "../common/MaintenancePage/Maintenance";
import { store } from "../store/store";
import MainLayout from "../layouts/MainLayout";
import i18n from "../i18n";

function MyApp({ Component, pageProps }) {
  const [isUnderMaintenance] = useState(false);
  const { status } = store.getState().auth;
  useEffect(() => {
    if (typeof window !== "undefined") {
      store.dispatch(getUserData());
      store.dispatch(getLocationData());
      store.dispatch(checkSystem());
    }
    if (status) {
      // console.log(status);
    }
  });
  useEffect(() => {
    sessionStorage.setItem("userAgent", navigator.userAgent);
    sessionStorage.setItem("amaref", uuidv4());
  }, []);
  useEffect(() => {
    OneSignal.init({
      appId: "05fee013-42d3-4e41-857d-5e4ee287b8d7",
    });
  }, []);

  if (isUnderMaintenance) {
    return <Maintenance />;
  }

  if (Component.getLayout) {
    return Component.getLayout(
      <>
        <Script
          strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}}`}
        />

        <Script id="google-analytics" strategy="lazyOnload">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
            });
         `}
        </Script>

        <Script
          src="https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js"
          defer
        />

        {process.env.NEXT_PUBLIC_ENV === "production" && (
          <Script id="zsiqchat" strategy="lazyOnload">
            {`
          var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || {
            widgetcode: "siqf916fa0fb4cd74ee03a6ec2a17175e56a327da4074c6abca5f6cdc2896cf655b",
            values:{},
            ready:function(){}
          };
          var d=document;
          var s=d.createElement("script");
          s.type="text/javascript";
          s.id="zsiqscript";
          s.defer=true;
          s.src="https://salesiq.zohopublic.com/widget";
          var t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);
        `}
          </Script>
        )}

        <I18nextProvider i18n={i18n}>
          <NoInternetConnection>
            <Provider store={store}>
              <ToastContainer />
              {/* <Countdown> */}
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
              {/* </Countdown> */}
            </Provider>
          </NoInternetConnection>
        </I18nextProvider>
      </>,
      pageProps.session
    );
  }
  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}}`}
      />

      <Script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
        `}
      </Script>
      <Script id="zsiqchat" strategy="lazyOnload">
        {`
          var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || {
            widgetcode: "siqf916fa0fb4cd74ee03a6ec2a17175e56a327da4074c6abca5f6cdc2896cf655b",
            values:{},
            ready:function(){}
          };
          var d=document;
          var s=d.createElement("script");
          s.type="text/javascript";
          s.id="zsiqscript";
          s.defer=true;
          s.src="https://salesiq.zohopublic.com/widget";
          var t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);
        `}
      </Script>
      <I18nextProvider i18n={i18n}>
        <NoInternetConnection>
          <Provider store={store}>
            <ToastContainer />
            {/* <Countdown> */}
            <ErrorBoundary>
              <MainLayout>
                <Component {...pageProps} />
              </MainLayout>
            </ErrorBoundary>
            {/* </Countdown> */}
          </Provider>
        </NoInternetConnection>
      </I18nextProvider>
    </>
  );
}
MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.shape.isRequired,
};
export default MyApp;
