import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/dashboardAPI";

export const getBookingOverview = createAsyncThunk(
  "GET BOOKING OVERVIEW",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getBookingOverview(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const uploadPhoto = createAsyncThunk(
  "UPLOAD USER PHOTO",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.uploadUserPhoto(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getPersonalInfo = createAsyncThunk(
  "GET PERSONAL INFO",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getPersonalInfo(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePersonalInfo = createAsyncThunk(
  "UPDATE PERSONAL INFO",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.updatePersonalInfo(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "CHANGE PASSWORD",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.changePassword(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFlightHistoryByEmail = createAsyncThunk(
  "GET FLIGHT HISTORY BY EMAIL",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getFlightHistoryByEmail(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFlightHistoryByToken = createAsyncThunk(
  "GET FLIGHT HISTORY BY TOKEN",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getFlightHistoryByToken(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUpcomingFlightHistoryByToken = createAsyncThunk(
  "GET UPCOMING FLIGHT HISTORY BY TOKEN",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getUpcomingFlightHistoryByToken(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPastFlightHistoryByToken = createAsyncThunk(
  "GET PAST FLIGHT HISTORY BY TOKEN",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getPastFlightHistoryByToken(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCancelledFlightHistoryByToken = createAsyncThunk(
  "GET CANCELLED FLIGHT HISTORY BY TOKEN",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getCancelledFlightHistoryByToken(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInsuranceHistory = createAsyncThunk(
  "GET INSURANCE HISTORY",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getInsuranceHistory(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUpcomingInsuranceHistory = createAsyncThunk(
  "GET UPCOMING INSURANCE HISTORY",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getUpcomingInsuranceHistory(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPastInsuranceHistory = createAsyncThunk(
  "GET PAST INSURANCE HISTORY",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getPastInsuranceHistory(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCancelledInsuranceHistory = createAsyncThunk(
  "GET CANCELLED INSURANCE HISTORY",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getCancelledInsuranceHistory(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllBookings = createAsyncThunk(
  "GET ALL BOOKINGS",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getAllBookings(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllFlightBookings = createAsyncThunk(
  "GET ALL FLIGHT BOOKINGS",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getAllFlightBookings(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllInsuranceBookings = createAsyncThunk(
  "GET ALL INSURANCE BOOKINGS",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getAllInsuranceBookings(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getABooking = createAsyncThunk(
  "GET A BOOKING",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getABooking(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
