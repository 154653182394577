/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import {
  staySearch,
  stayViaID,
  bookStay,
  stayPerCity,
  stayPrice,
  getListingReviews,
  getListingReviewsNegative,
  getListingReviewsPositive,
  submitUserRatings,
  bookStayAmadeus,
  bookStayHotelsPro,
} from "./stayThunk";

const initialState = {
  isLoading: false,
  isLoadingPositive: false,
  isLoadingNegative: false,
  listingReviews: null,
  listingReviewsPositive: null,
  listingReviewsNegative: null,
  listingReviewsLatest: null,
  results: null,
  selected: false,
  confimation: null,
  percitystays: null,
  cityloader: false,
  price: null,
  userRatings: null,
  submitedRating: false,
};

export const staySlice = createSlice({
  name: "stay",
  initialState,
  reducers: {
    setListingReviewsLatest: (state, action) => {
      state.listingReviewsLatest = action.payload;
    },
    setSubmitedRating: (state, action) => {
      state.submitedRating = action.payload;
    },
  },
  extraReducers: {
    [stayPrice.pending]: (state) => {
      state.isLoading = true;
    },
    [stayPrice.fulfilled]: (state, action) => {
      state.price = action.payload;
      state.isLoading = false;
    },
    [stayPrice.rejected]: (state) => {
      state.isLoading = false;
    },
    [stayPerCity.pending]: (state) => {
      state.cityloader = true;
    },
    [stayPerCity.fulfilled]: (state, action) => {
      state.percitystays = action.payload;
      state.cityloader = false;
    },
    [stayPerCity.rejected]: (state) => {
      state.cityloader = false;
    },
    [staySearch.pending]: (state) => {
      state.isLoading = true;
    },
    [staySearch.fulfilled]: (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    },
    [staySearch.rejected]: (state) => {
      state.isLoading = false;
    },
    [stayViaID.pending]: (state) => {
      state.isLoading = true;
    },
    [stayViaID.fulfilled]: (state, action) => {
      state.selected = action.payload;
      state.isLoading = false;
    },
    [stayViaID.rejected]: (state) => {
      state.isLoading = false;
    },

    [bookStayAmadeus.pending]: (state) => {
      state.isLoading = true;
    },
    [bookStayAmadeus.fulfilled]: (state, action) => {
      state.confimation = action.payload;
      state.isLoading = false;
    },
    [bookStayAmadeus.rejected]: (state) => {
      state.isLoading = false;
    },

    [bookStayHotelsPro.pending]: (state) => {
      state.isLoading = true;
    },
    [bookStayHotelsPro.fulfilled]: (state, action) => {
      state.confimation = action.payload;
      state.isLoading = false;
    },
    [bookStayHotelsPro.rejected]: (state) => {
      state.isLoading = false;
    },
    [bookStay.pending]: (state) => {
      state.isLoading = true;
    },
    [bookStay.fulfilled]: (state, action) => {
      state.confimation = action.payload;
      state.isLoading = false;
    },
    [bookStay.rejected]: (state) => {
      state.isLoading = false;
    },
    [getListingReviews.pending]: (state) => {
      state.isLoading = true;
    },
    [getListingReviews.fulfilled]: (state, action) => {
      state.listingReviews = action.payload;
      state.isLoading = false;
    },
    [getListingReviews.rejected]: (state) => {
      state.isLoading = false;
    },
    [getListingReviewsPositive.pending]: (state) => {
      state.isLoadingPositive = true;
    },
    [getListingReviewsPositive.fulfilled]: (state, action) => {
      state.listingReviewsPositive = action.payload;
      state.isLoadingPositive = false;
    },
    [getListingReviewsPositive.rejected]: (state) => {
      state.isLoadingPositive = false;
    },
    [getListingReviewsNegative.pending]: (state) => {
      state.isLoadingNegative = true;
    },
    [getListingReviewsNegative.fulfilled]: (state, action) => {
      state.listingReviewsNegative = action.payload;
      state.isLoadingNegative = false;
    },
    [getListingReviewsNegative.rejected]: (state) => {
      state.isLoadingNegative = false;
    },
    [submitUserRatings.pending]: (state) => {
      state.isLoading = true;
    },
    [submitUserRatings.fulfilled]: (state, action) => {
      state.userRatings = action.payload;
      state.isLoading = false;
      toast.success("Reviews submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [submitUserRatings.rejected]: (state) => {
      state.isLoading = false;
      toast.error("Submit review failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  },
});

export const { setSubmitedRating } = staySlice.actions;
export default staySlice.reducer;
