import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import Link from "next/link";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  setLoginModal,
  setSignUpModal,
} from "../../../features/auth/authSlice";
import { Register } from "../../../features/auth/authThunk";

function SignupFormModal() {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [showSecPasswordError, setshowSecPasswordError] = useState(false);
  const { agent, Created } = useSelector((state) => state.auth);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  // const termsRef = useRef();
  const confirmPasswordRef = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [terms, setTerms] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [setDisableEmailButton] = useState(true);
  // const [timer, setTimer] = useState(60);
  // const timeOutCallback = useCallback(
  //   () => setTimer((currTimer) => currTimer - 1),
  //   []
  // );
  // useEffect(() => {
  //   if (timer > 0 && Created === true) {
  //     setTimeout(timeOutCallback, 1000);
  //   }
  //   if (timer === 0) {
  //     setDisableEmailButton(false);
  //   }
  // }, [Created, timer, timeOutCallback]);

  const emailRef = useRef();
  const passwordRef = useRef();
  const rmmbrMeRef = useRef();
  const [email, setEmail] = useState("");
  const [rmmbrMe, setRmmbrMe] = useState(false);
  const handleScroll = () => {
    if (componentRef.current) {
      setIsActive(componentRef.current.scrollTop >= 40);
    }
  };
  // const handleError = (value) => {
  //   if (value !== password) {
  //     setshowSecPasswordError(true);
  //   } else {
  //     setshowSecPasswordError(false);
  //   }
  // };
  // const data = {
  //   email,
  //   firstName,
  //   lastName,
  //   password: confirmPassword,
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      Register({
        email,
        firstName,
        lastName,
        password: confirmPassword,
        agent,
      })
    );
  };
  useEffect(() => {
    const componentElement = componentRef.current;
    componentElement.addEventListener("scroll", handleScroll);

    return () => {
      componentElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeModal = (e) => {
    e.preventDefault();
    dispatch(setSignUpModal(false));
  };

  const openLogin = (e) => {
    e.preventDefault();
    dispatch(setLoginModal(true));
    dispatch(setSignUpModal(false));
  };
  return (
    <div
      ref={componentRef}
      className=" z-[816] sm:overflow-y-auto sm:p-10 bg-[rgb(0,0,0,0.5)] w-full h-full fixed inset-0 overflow-x-hidden "
    >
      <div className=" fixed top-[32px] my-0 mx-auto sm:relative sm:top-0 sm:max-w-[360px] items-start sm:items-center min-h-full flex w-full rounded-t-[12px] transition-[top] ease-in-out duration-300   ">
        <div className=" sm:relative absolute bottom-[32px]  sm:bottom-auto max-h-[calc((100%-32px)-0px)] rounded-[9px] pb-0 h-auto sm:max-h-full overflow-hidden sm:overflow-visible bg-white w-full shadow-md ">
          <div
            className={` ${
              isActive ? "sm:right-auto sm:fixed" : "absolute right-0"
            }  flex top-0 z-[800] justify-end items-center h-[52px] w-full max-w-[360px]`}
          >
            {!Created && (
              <button
                type="button"
                className=" hover:bg-tramangolightOrange mr-1 h-[44px] relative flex justify-between items-center text-center max-w-full bg-transparent rounded-[6px] font-medium text-[15px] w-[44px] transition-all ease-in-out duration-300   "
                onClick={(e) => closeModal(e)}
              >
                <div className="  align-middle h-full w-full flex justify-center items-center">
                  <div className=" flex items-center h-full w-full justify-center">
                    <svg
                      className=" text-[rgb(79,94,113)] w-5 h-5 shrink-0 align-middle "
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M17.656 6.333a.9.9 0 0 1 0 1.273l-4.046 4.052a.48.48 0 0 0 0 .678l4.047 4.053a.9.9 0 0 1 .08 1.18l-.081.092a.9.9 0 0 1-1.273 0l-4.044-4.05a.48.48 0 0 0-.68 0l-4.042 4.05a.9.9 0 1 1-1.274-1.273l4.047-4.052a.48.48 0 0 0 0-.678L6.343 7.606a.9.9 0 0 1-.08-1.18l.081-.093a.9.9 0 0 1 1.273.001l4.043 4.049a.48.48 0 0 0 .679 0l4.044-4.049a.9.9 0 0 1 1.273 0Z" />
                    </svg>
                  </div>
                </div>
              </button>
            )}
          </div>
          <div className="  mb-0 pt-[32px] px-4 sm:px-[32px] w-full block rounded-t-[12px] bg-white ">
            <Image
              height={140}
              width={140}
              className=" inline-block max-h-[140px] max-w-full bg-transparent "
              src="/world-bro.svg"
            />
            <div className="mt-4">
              <h1 className=" pr-[32px] text-[22px] font-semibold sm:text-[28px] sm:font-bold">
                {Created ? (
                  <>
                    {" "}
                    <p>{t("aVerificationLink")}</p>
                    <p style={{ fontSize: "14px" }}>{email}</p>
                  </>
                ) : (
                  <>{t("createNewAccount")}</>
                )}
              </h1>
            </div>
          </div>
          <section className=" rounded-b-none sm:rounded-b-[9px] py-6 px-4 sm:p-[32px] w-full bg-white pb-10  ">
            {!Created && (
              <p className=" text-[16px] font-bold text-[rgb(37,42,49)] leading-[24px] mb-2 text-left ">
                {t("SignUpEmail")}
              </p>
            )}

            {Created ? (
              <div className="  gap-3 flex flex-col items-center w-full mb-4 " />
            ) : (
              <form
                onSubmit={(e) => handleSubmit(e)}
                className="  gap-3 flex flex-col items-center w-full mb-4 "
              >
                <div className=" w-full  flex relative items-center justify-between h-[44px] ">
                  <input
                    placeholder="Firstname"
                    className=" appearance-none border focus:border-tramangoOrange rounded-[6px] bg-transparent px-3 w-full h-full z-[2]  "
                    ref={firstNameRef}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    id="firstName"
                    name="firstName"
                    required
                  />
                </div>
                <div className=" w-full  flex relative items-center justify-between h-[44px] ">
                  <input
                    placeholder="Lastname"
                    className=" appearance-none border focus:border-tramangoOrange rounded-[6px] bg-transparent px-3 w-full h-full z-[2]  "
                    ref={lastNameRef}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    id="lastName"
                    name="lastName"
                    required
                  />
                </div>
                <div className=" w-full  flex relative items-center justify-between h-[44px] ">
                  <input
                    placeholder="e.g. your@email.com"
                    className=" appearance-none border focus:border-tramangoOrange rounded-[6px] bg-transparent px-3 w-full h-full z-[2]  "
                    ref={emailRef}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    id="email"
                    name="email"
                    required
                  />
                </div>
                <div className=" w-full flex relative items-center border focus-within:border-tramangoOrange rounded-[6px] justify-between h-[44px] ">
                  <input
                    placeholder="Password"
                    className=" appearance-none bg-transparent px-3 w-full h-full z-[2]"
                    ref={passwordRef}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={!show ? "password" : "text"}
                    id="userPassword"
                    name="userPassword"
                    required
                  />
                  {!show ? (
                    <span
                      onClick={() => setShow(true)}
                      className="flex cursor-pointer select-none items-center px-3 dark:text-background-foregroundD text-gray-900"
                    >
                      <FaEye classname=" h-6 w-6" />
                    </span>
                  ) : (
                    <span
                      onClick={() => setShow(false)}
                      className="flex select-none cursor-pointer items-center px-3 dark:text-background-foregroundD text-gray-900"
                    >
                      <FaEyeSlash classname=" h-6 w-6" />
                    </span>
                  )}
                </div>
                <div className=" w-full flex relative items-center justify-between h-[44px] ">
                  <input
                    placeholder="Confirm password"
                    className=" appearance-none border focus:border-tramangoOrange rounded-[6px] bg-transparent px-3 w-full h-full z-[2]  "
                    ref={confirmPasswordRef}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    // onInput={(e) => handleError(e.target.value)}
                    type="password"
                    id="userPassword"
                    name="userPassword"
                    required
                  />
                </div>

                <div className="flex w-full my-3 items-center text-sm justify-between">
                  <span className="flex h-8 items-center gap-2">
                    <label
                      className=" flex items-center text-[13px]"
                      htmlFor="rmmbrMe"
                    >
                      <input
                        type="checkbox"
                        name="rmmbrMe"
                        id="rmmbrMe"
                        ref={rmmbrMeRef}
                        value={rmmbrMe}
                        onChange={(e) => setRmmbrMe(e.currentTarget.checked)}
                        className="h-4 w-4 rounded shrink-0"
                        required
                      />
                      <div className=" sm:mt-0.5 leading-none text-sm">
                        {t("byContinue")}
                        <a
                          href="https://company.tramango.com/Terms-of-use/#Privacy-Policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <strong className=" cursor-pointer text-tramangoOrange underline">
                            {" "}
                            {t("privacyPolicy")}{" "}
                          </strong>{" "}
                        </a>
                        {t("and")}
                        <a
                          href="https://company.tramango.com/Terms-of-use/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <strong className=" cursor-pointer text-tramangoOrange underline">
                            {" "}
                            {t("termsConditions")}
                          </strong>{" "}
                        </a>
                      </div>
                    </label>
                  </span>
                </div>

                <button
                  type="submit"
                  className=" relative h-[44px] flex justify-between items-center text-center max-w-full transition-colors ease-in-out duration-500 hover:bg- bg-tramangoOrange hover:bg-[#e4550b] text-white px-4 rounded-[6px] w-full cursor-pointer"
                >
                  <div className=" align-middle h-full flex w-full justify-start items-center">
                    <div className=" text-[15px] font-medium inline-block w-full text-center leading-[1]">
                      {t("createAccount")}
                    </div>
                  </div>
                </button>
                <div className="text-[13px] text-[rgb(37,42,49)] ">
                  {t("haveAccount")}{" "}
                  <button type="button" onClick={(e) => openLogin(e)}>
                    <p className="cursor-pointer  text-[rgb(37,42,49)] underline font-semibold text-[13px] inline-flex items-center">
                      {t("login")}
                    </p>
                  </button>
                </div>
              </form>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

export default SignupFormModal;
