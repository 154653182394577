import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { setLocale } from "../../features/auth/authSlice";

function LanguageDropdown() {
  const { i18n } = useTranslation();
  const { locale } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLanguageChange = (val) => {
    // Update the Redux state with the selected language
    dispatch(setLocale(val));
    // Change the language using i18n
    i18n.changeLanguage(val);
    // Store the selected language in local storage
    localStorage.setItem("selectedLanguage", val);
  };

  return (
    <ul className={styles.languageDropdown}>
      <li
        className={`${styles.options} ${
          locale === "en" ? styles.active : null
        }`}
        onClick={() => handleLanguageChange("en")}
      >
        English (EN)
      </li>
      <li
        className={`${styles.options} ${
          locale === "fr" ? styles.active : null
        }`}
        onClick={() => handleLanguageChange("fr")}
      >
        French (FR)
      </li>
    </ul>
  );
}

export default LanguageDropdown;
