import React from "react";
import PropTypes from "prop-types";
import ErrorCard from "./ErrorCard";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    // Destructure the state object to get the hasError property
    const { hasError } = this.state;

    // Check if the error is thrown
    if (hasError) {
      // You can render any custom fallback UI

      return (
        <ErrorCard
          title="Something went wrong"
          subtitle=" We have hit a small snag but our team is on it, feel free to
          explore other services on our platform or grab a cup of coffee.
          If you need immediate assistance, our support team is just a
          click away. We will be back on track shortly. Thank you for your
          patience!"
          image="/images/error/systemerror.png"
        />
      );
    }

    // Return children components in case of no error
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
