import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/experienceAPI";

export const purchaseExperience = createAsyncThunk(
  "PURCHASE EXPERIENCE",
  async (payload) => {
    const response = await api.purchaseExperience(payload);
    return response;
  }
);

export const getListingReviews = createAsyncThunk(
  "GET EXPERIENCE LISTING REVIEWS",
  async (payload) => {
    const response = await api.getListingReviews(payload);
    return response;
  }
);

export const getListingReviewsNegative = createAsyncThunk(
  "GET EXPERIENCE NEGATIVE LISTING REVIEWS",
  async (payload) => {
    const response = await api.getListingReviewsNegative(payload);
    return response;
  }
);

export const getListingReviewsPositive = createAsyncThunk(
  "GET EXPERIENCE POSITIVE LISTING REVIEWS ",
  async (payload) => {
    const response = await api.getListingReviewsPositive(payload);
    return response;
  }
);

export const viewExperience = createAsyncThunk(
  "VIEW EXPERIENCE",
  async (payload) => {
    const response = await api.viewExperienceWithID(payload);
    return response;
  }
);

export const experienceSearch = createAsyncThunk(
  "SEARCH EXPERIENCE",
  async (payload) => {
    const response = await api.experienceSearch(payload);
    return response;
  }
);

export const experienceToursSearch = createAsyncThunk(
  "SEARCH EXPERIENCE TOURS",
  async (payload) => {
    const response = await api.experienceToursSearch(payload);
    return response;
  }
);

export const experienceAttractionSearch = createAsyncThunk(
  "SEARCH EXPERIENCE ATTRACTION",
  async (payload) => {
    const response = await api.experienceAttractionSearch(payload);
    return response;
  }
);

export const experienceOutdoorsSearch = createAsyncThunk(
  "SEARCH EXPERIENCE OUTDOORS",
  async (payload) => {
    const response = await api.experienceOutdoorSearch(payload);
    return response;
  }
);

export const experienceRelaxationSearch = createAsyncThunk(
  "SEARCH EXPERIENCE RELAXATION",
  async (payload) => {
    const response = await api.experienceRelaxationSearch(payload);
    return response;
  }
);

export const getAttractions = createAsyncThunk(
  "GET ATTRACTIONS",
  async (payload) => {
    const response = await api.getAttractions(payload);
    return response;
  }
);

export const getOutdoor = createAsyncThunk("GET OUTDOOR", async (payload) => {
  const response = await api.getOutdoorAndSport(payload);
  return response;
});

export const getRelaxation = createAsyncThunk(
  "GET RELAXATION",
  async (payload) => {
    const response = await api.getRelaxation(payload);
    return response;
  }
);

export const getTours = createAsyncThunk("GET TOURS", async (payload) => {
  const response = await api.getTours(payload);
  return response;
});

export const submitUserRatings = createAsyncThunk(
  "SUBMIT USER REVIEW FOR EXPERIENCE",
  async (payload) => {
    const response = await api.rateExperience(payload);
    return response;
  }
);

export const getInformationalwithID = createAsyncThunk(
  "GET_INFORMATIONAL_WITH_ID",
  async (payload) => {
    const response = await api.getInformationalwithID(payload);
    return response;
  }
);
