import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { RxDotFilled } from "react-icons/rx";
// import { hasCookie, setCookie, deleteCookie } from "cookies-next";

function cookieCustomize({ setShowCustomize }) {
  const [isActive, setIsActive] = useState(false);
  const componentRef = useRef(null);
  const { t } = useTranslation();
  const handleScroll = () => {
    if (componentRef.current) {
      setIsActive(componentRef.current.scrollTop >= 70);
    }
  };

  useEffect(() => {
    const componentElement = componentRef.current;
    componentElement.addEventListener("scroll", handleScroll);

    return () => {
      componentElement.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="block">
      <div
        ref={componentRef}
        className={` md:overflow-y-auto md:p-10 bg-[rgba(0,0,0,0.5)] h-full w-full fixed inset-0 z-[800] overflow-x-hidden outline-none`}
      >
        <div className="relative top-0 max-w-[672px] transition-[top] duration-500 ease-in-out rounded-t-[12px] w-full items-center min-h-full flex mx-auto  ">
          <div className=" relative bottom-auto rounded-[9px] pb-0 h-auto overflow-visible  max-h-full bg-white w-full shadow-lg  ">
            <div
              className={` ${
                isActive
                  ? "bg-white shadow-md right-auto fixed"
                  : "right-0 absolute"
              } rounded-none top-0 z-[800] flex justify-end items-center w-full h-[52px] max-w-[672px] transition-colors ease-in-out duration-150`}
            >
              <button
                type="button"
                className=" mr-1 h-[44px] hover:bg-tramangolightOrange rounded-[6px] relative flex justify-between items-center text-center max-w-full appearance-none bg-transparent w-[44px]"
                onClick={() => setShowCustomize(false)}
              >
                <div className=" align-middle h-full w-full flex justify-center items-center ">
                  <div className=" flex flex-row items-center justify-center ">
                    <svg
                      className=" text-[rgb(79,94,113)] w-5 h-5 shrink-0 align-middle "
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M17.656 6.333a.9.9 0 0 1 0 1.273l-4.046 4.052a.48.48 0 0 0 0 .678l4.047 4.053a.9.9 0 0 1 .08 1.18l-.081.092a.9.9 0 0 1-1.273 0l-4.044-4.05a.48.48 0 0 0-.68 0l-4.042 4.05a.9.9 0 1 1-1.274-1.273l4.047-4.052a.48.48 0 0 0 0-.678L6.343 7.606a.9.9 0 0 1-.08-1.18l.081-.093a.9.9 0 0 1 1.273.001l4.043 4.049a.48.48 0 0 0 .679 0l4.044-4.049a.9.9 0 0 1 1.273 0Z" />
                    </svg>
                  </div>
                </div>
              </button>
            </div>
            <div className="mb-0 pt-8 px-8 w-full rounded-t-[12px] bg-white  ">
              <div>
                <h1 className=" pr-8 text-[28px] font-[700] ">
                  {t("privacysettings")}
                </h1>
              </div>
              <div
                className={`${
                  isActive ? "fixed top-0  " : " top-[-40px] hidden"
                } transition-[top] duration-300 ease-in-out mb-0 whitespace-nowrap inline-block left-auto right-auto  text-[18px] font-[700] p-0  z-[800] max-w-[calc(652px)] leading-[52px] `}
              >
                {t("privacysettings")}
              </div>
            </div>

            <section className="pb-10 mb-[108px] p-8 w-full bg-white">
              <p className="text-[15px] text-[rgb(79,94,113)] font-normal text-left mb-[24px]  ">
                {t("adjustprivacy")}{" "}
                <a href="/en/pages/content/privacy">{t("privacyPolicy")}</a>.
                {t("cookielist")}{" "}
                <a href="/en/pages/content/privacy#cookies">{t("here")}</a>.
              </p>
              <div className=" w-full">
                <hr className=" h-[1px] bg-[rgb(232,237,241)] mb-4 " />
              </div>
              <div className="mb-[24px] w-full">
                <div className=" gap-4 flex flex-col md:flex-row items-start w-full mb-4 ">
                  <div className="w-full md:max-w-[220px] ">
                    <p className=" text-[15px] font-[700] text-[rgb(37,42,49)] mb-2 text-left ">
                      {t("necessary")}
                    </p>
                  </div>
                  <div className=" gap-4 flex flex-col w-full items-start  ">
                    <p className=" text-[13px] font-[400] text-[rgb(37,42,49)] text-left mb-4  ">
                      {t("cookieshelp")}
                    </p>
                    <div className=" w-full mb-6 ">
                      <label className=" flex w-full items-start cursor-not-allowed relative   ">
                        <input className=" absolute opacity-0  " />
                        <div className=" relative rounded-full  flex items-center justify-center h-5 w-5 bg-[rgb(232,237,241)]  border border-[rgb(232,237,241)]">
                          <span className=" w-2 h-2 bg-[rgb(232,237,241)] shrink-0  " />
                        </div>
                        <div className=" flex flex-col font-medium ml-2 opacity-50 ">
                          <span className=" text-[15px]">{t("enabled")}</span>
                        </div>
                      </label>
                    </div>
                    <div className=" w-full">
                      <hr className=" h-[1px] bg-[rgb(232,237,241)] mb-4 " />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-[24px] w-full">
                <div className=" gap-4 flex flex-col md:flex-row items-start w-full mb-4 ">
                  <div className="w-full md:max-w-[220px] ">
                    <p className=" text-[15px] font-[700] text-[rgb(37,42,49)] mb-2 text-left ">
                      {t("performance")}
                    </p>
                  </div>
                  <div className=" gap-4 flex flex-col w-full items-start  ">
                    <p className=" text-[13px] font-[400] text-[rgb(37,42,49)] text-left mb-4  ">
                      {t("cookieshelp2")}
                    </p>
                    <div className=" gap-4 flex-grow shrink-0 inline-flex flex-row items-center place-content-start mb-6 ">
                      <label className=" relative flex w-full self-start cursor-pointer   ">
                        <input
                          type="radio"
                          className=" m-0 absolute opacity-0  "
                        />
                        <div className=" relative rounded-full  flex items-center justify-center h-5 w-5 bg-tramangoOrange  border border-tramangoOrange">
                          <span className=" w-2 h-2 rounded-full bg-white shrink-0  " />
                        </div>
                        <div className=" flex flex-col font-medium ml-2 ">
                          <span className=" text-[15px]">{t("enabled")}</span>
                        </div>
                      </label>
                      <label className=" flex w-full self-start cursor-pointer relative   ">
                        <input type="radio" className=" absolute opacity-0  " />
                        <div className=" relative rounded-full  flex items-center justify-center h-5 w-5 bg-white border border-[rgb(232,237,241)]">
                          <span className=" w-2 h-2 rounded-full bg-white shrink-0  " />
                        </div>
                        <div className=" flex flex-col font-medium ml-2 ">
                          <span className=" text-[15px]">{t("disabled")}</span>
                        </div>
                      </label>
                    </div>
                    <div className=" w-full">
                      <hr className=" h-[1px] bg-[rgb(232,237,241)] mb-4 " />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-[24px] w-full">
                <div className=" gap-4 flex flex-col md:flex-row items-start w-full mb-4 ">
                  <div className="w-full md:max-w-[220px] ">
                    <p className=" text-[15px] font-[700] text-[rgb(37,42,49)] mb-2 text-left ">
                      {t("marketing")}
                    </p>
                  </div>
                  <div className=" gap-4 flex flex-col w-full items-start  ">
                    <p className=" text-[13px] font-[400] text-[rgb(37,42,49)] text-left mb-4  ">
                      {t("cookieshelp3")}
                    </p>
                    <ul className=" flex w-full flex-col text-[13px] appearance-none   ">
                      <li className="flex mb-[4px]">
                        <div className=" flex items-center mr-2 h-4 ">
                          <RxDotFilled className=" align-middle h-4 w-4 shrink-0 " />
                        </div>
                        <span className="w-full">
                          <strong>{t("marketingprofile")}</strong>{" "}
                          {t("marketingprofiletext")}
                        </span>
                      </li>
                      <li className="flex mb-[4px]">
                        <div className=" flex items-center mr-2 h-4 ">
                          <RxDotFilled className=" align-middle h-4 w-4 shrink-0 " />
                        </div>
                        <span className="w-full">
                          <strong>{t("targeting")}</strong> {t("targetingText")}
                        </span>
                      </li>
                      <li className="flex mb-[4px]">
                        <div className=" flex items-center mr-2 h-4 ">
                          <RxDotFilled className=" align-middle h-4 w-4 shrink-0 " />
                        </div>
                        <span className="w-full">
                          <strong>{t("advertising")}</strong>{" "}
                          {t("advertisingText")}
                        </span>
                      </li>
                      <li className="flex mb-[4px]">
                        <div className=" flex items-center mr-2 h-4 ">
                          <RxDotFilled className=" align-middle h-4 w-4 shrink-0 " />
                        </div>
                        <span className="w-full">
                          <strong>{t("lookalike")}</strong>
                          {t("lookalikeText")}
                        </span>
                      </li>
                    </ul>
                    <div className=" gap-4 flex-grow shrink-0 inline-flex flex-row items-center place-content-start mb-6 ">
                      <label className=" relative flex w-full self-start cursor-pointer   ">
                        <input
                          type="radio"
                          className=" m-0 absolute opacity-0  "
                        />
                        <div className=" relative rounded-full  flex items-center justify-center h-5 w-5 bg-tramangoOrange  border border-tramangoOrange">
                          <span className=" w-2 h-2 rounded-full bg-white shrink-0  " />
                        </div>
                        <div className=" flex flex-col font-medium ml-2 ">
                          <span className=" text-[15px]">{t("enabled")}</span>
                        </div>
                      </label>
                      <label className=" flex w-full self-start cursor-pointer relative   ">
                        <input type="radio" className=" absolute opacity-0  " />
                        <div className=" relative rounded-full  flex items-center justify-center h-5 w-5 bg-white border border-[rgb(232,237,241)]">
                          <span className=" w-2 h-2 rounded-full bg-white shrink-0  " />
                        </div>
                        <div className=" flex flex-col font-medium ml-2 ">
                          <span className=" text-[15px]">{t("disabled")}</span>
                        </div>
                      </label>
                    </div>
                    <div className=" w-full">
                      <hr className=" h-[1px] bg-[rgb(232,237,241)] mb-4 " />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className=" fixed bottom-0 w-full max-w-[672px] shadow-2xl p-8 z-[800] justify-end flex bg-white ">
              <div className="flex gap-4 shrink-0 flex-grow items-start w-full">
                <button
                  type="button"
                  className=" h-[44px] max-w-full flex-1 bg-tramangolightOrange flex justify-between relative items-center text-center px-4 rounded-md w-full "
                >
                  <div className=" w-full align-middle flex h-full justify-center items-center text-center ">
                    <div className=" inline-block w-full">
                      <div
                        className=" text-center text-[15px] font-bold"
                        onClick={() => setShowCustomize(false)}
                      >
                        {t("saveSettings")}
                      </div>
                    </div>
                  </div>
                </button>
                <button
                  type="button"
                  className=" h-[44px] max-w-full flex-1 bg-tramangoOrange text-white flex justify-between relative items-center text-center px-4 rounded-md w-full "
                >
                  <div className=" w-full align-middle flex h-full justify-center items-center text-center ">
                    <div className=" inline-block w-full">
                      <div
                        className=" text-center text-[15px] font-bold"
                        onClick={() => setShowCustomize(false)}
                      >
                        {t("acceptall")}
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
cookieCustomize.propTypes = {
  setShowCustomize: PropTypes.bool.isRequired, // New boolean prop
};

export default cookieCustomize;
