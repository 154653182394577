import Image from "next/image";
import Link from "next/link";
import React from "react";

const index = () => {
  return (
    <div className=" relative min-h-screen bg-gray-50 flex flex-col items-center  py-12 sm:px-6 lg:px-8 z-10">
      <div className=" pb-[64px] p-[24px] w-full mx-auto max-w-[960px] ">
        <div className="sm-mx-auto sm:w-full sm:max-w-md">
          <div className="flex flex-col items-center justify-center">
            <div className=" h-full mb-9">
              <Image
                height={278}
                width={414}
                className=" inline-block max-h-[120px] max-w-full bg-transparent "
                src="/images/error/systemerror.png"
              />
            </div>
            <div className=" max-w-[470px] my-[24px]  ">
              <h2 className=" text-center">Something went wrong</h2>
              <div className=" text-[16px] text-[rgb(79,94,113)] font-normal text-center leading-[24px]">
                We have hit a small snag but our team is on it, feel free to
                explore other services on our platform or grab a cup of coffee.
                If you need immediate assistance, our support team is just a
                click away. We will be back on track shortly. Thank you for your
                patience!
              </div>
            </div>
            <div className=" flex w-full justify-center ">
              <div className=" w-auto cursor-pointer ">
                <Link href="https://support.tramango.com/">
                  <div className=" h-[44px] rounded-[3px] px-4 inline-flex justify-between items-center text-center max-w-full bg-tramangoOrange mb-[24px] w-auto text-[15px] font-medium ">
                    <div className=" align-middle h-full flex justify-between items-center">
                      <div className=" text-white flex items-center">
                        Contact Support
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
