/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import {
  PlaneSearch,
  ConfirmPrice,
  OrderFlight,
  getPopularTrips,
  getBlogs,
  setFlightPayment,
  Seatmap,
  GetChargableBags,
  FareRules,
  checkServerStatus,
} from "./flightThunk";

export const filters = {
  AIRLINE: "AIRLINE",
  STOPS: "STOPS",
  PRICE_RANGE: "PRICE_RANGE",
};
export const sorters = {
  RECOMMENDED: "RECOMMENDED",
  CHEAPEST: "CHEAPEST",
  SHORTEST: "SHORTEST",
  FLEXIBLE: "FLEXIBLE",
};
export const stoppers = {
  ALL: "ALL",
  NONSTOP: "NONSTOP",
  ONESTOP: "ONESTOP",
  TWOSTOP: "TWOSTOP",
  THREESTOP: "THREESTOP",
  FOURSTOP: "FOURSTOP",
};
const initialState = {
  farerules: null,
  seatData: [],
  chargablebags: null,
  seatmap: null,
  selectedSeat: null,
  isError: false,
  errorcode: null,
  flightlist: null,
  stops: "ALL",
  filterby: null,
  sortby: "RECOMMENDED",
  flightLoader: false,
  confirmationLoader: false,
  orderLoader: false,
  ptloading: false,
  triptype: "singleTrip",
  numberofAdults: 1,
  numberofInfants: 0,
  numberofHeldInfants: 0,
  numberofChildren: 0,
  ticketType: "Economy",
  selectedTicketType: "ECONOMY",
  flightResults: [],
  travellersdetails: [],
  errormessage: null,
  selectedFlightOption: [],
  confirmedFlight: null,
  offers: null,
  order: null,
  populartrips: null,
  blogs: null,
  bookingData: null,
  email: "",
  fdate: null,
  payment: "",
  tinfo: null,
  paid: null,
  error: false,
  amadeusError: null,
  data: null,
  loading: false,
  checkError: null,
  flightdata: {
    data: {
      currencyCode: "USD",
      originDestinations: "",
      travelers: [],
      sources: ["GDS"],
      searchCriteria: {
        maxFlightOffers: 30,
        flightFilters: {
          cabinRestrictions: [
            {
              cabin: "",
              coverage: "MOST_SEGMENTS",
              originDestinationIds: ["1"],
            },
          ],
          carrierRestrictions: {
            excludedAirlineCodes: filters.airline,
          },
          maxNumberOfConnections: 0,
        },
      },
    },
  },
  selectedDeparture: {
    one: null,
    two: null,
    three: null,
    four: null,
    five: null,
  },
  selectedArrival: {
    one: null,
    two: null,
    three: null,
    four: null,
    five: null,
  },
  rounddate: [null, null],
  date: {
    one: null,
    two: null,
    three: null,
    four: null,
    five: null,
  },
  filters: {
    airline: [],
    stops: null,
    pricerange: [],
    active: false,
  },
  departureiataString: {
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
  },
  arrivaliataString: {
    one: null,
    two: null,
    three: null,
    four: null,
    five: null,
  },
};

export const flightSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {
    updateAdditionalService: (state, action) => {
      const { segmentIds, additionalServiceData, travelerId } = action.payload;

      const findTravelerIndex = (traveler) =>
        traveler.travelerId === travelerId;

      const flightOffer = state.confirmedFlight?.data.flightOffers[0];

      if (!flightOffer) {
        state.offers = flightOffer;
        return;
      }

      const updatedTravelerPricings = [...flightOffer.travelerPricings];

      updatedTravelerPricings.forEach((travelerPricing) => {
        if (findTravelerIndex(travelerPricing)) {
          segmentIds.forEach((segmentId) => {
            const segmentIndex = travelerPricing.fareDetailsBySegment.findIndex(
              (fareDetail) => fareDetail.segmentId === segmentId
            );

            if (segmentIndex !== -1) {
              const fareDetail =
                travelerPricing.fareDetailsBySegment[segmentIndex];
              const updatedAdditionalServices = {
                ...fareDetail.additionalServices,
                ...additionalServiceData,
              };
              fareDetail.additionalServices = updatedAdditionalServices;
            }
          });
        }
      });

      const updatedConfirmedFlight = {
        ...state.confirmedFlight,
        data: {
          ...state.confirmedFlight.data,
          flightOffers: [
            {
              ...flightOffer,
              travelerPricings: updatedTravelerPricings,
            },
          ],
        },
      };

      state.confirmedFlight = updatedConfirmedFlight;
      state.offers = flightOffer;
    },

    removeSeats: (state, action) => {
      const { segmentIds, travelerId } = action.payload;

      const findTravelerIndex = (traveler) =>
        traveler.travelerId === travelerId;

      const flightOffer = state.confirmedFlight?.data.flightOffers[0];

      if (!flightOffer) {
        state.offers = flightOffer;
        return;
      }

      const updatedTravelerPricings = [...flightOffer.travelerPricings];

      updatedTravelerPricings.forEach((travelerPricing) => {
        if (findTravelerIndex(travelerPricing)) {
          segmentIds.forEach((segmentId) => {
            const segmentIndex = travelerPricing.fareDetailsBySegment.findIndex(
              (fareDetail) => fareDetail.segmentId === segmentId
            );

            if (segmentIndex !== -1) {
              travelerPricing.fareDetailsBySegment[
                segmentIndex
              ].additionalServices = null;
            }
          });
        }
      });

      const updatedConfirmedFlight = {
        ...state.confirmedFlight,
        data: {
          ...state.confirmedFlight.data,
          flightOffers: [
            {
              ...flightOffer,
              travelerPricings: updatedTravelerPricings,
            },
          ],
        },
      };

      state.confirmedFlight = updatedConfirmedFlight;
      state.offers = flightOffer;
    },

    reset: () => initialState,
    clearConfirmed: (state) => {
      state.confirmedFlight = null;
    },
    setSeatData: (state, action) => {
      state.seatData = action.payload;
    },
    setOrigindestination(state, action) {
      state.flightdata.data.originDestinations = action.payload;
    },
    setFlightlist(state, action) {
      state.flightlist = action.payload;
    },

    setError(state) {
      state.isError = false;
      state.errorcode = null;
    },
    setStop(state, action) {
      state.stops = action.payload;
    },
    filterBy(state, action) {
      state.filterby = action.payload;
    },
    sortBy(state, action) {
      state.sortby = action.payload;
    },
    filterByStops: (state, action) => {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line no-unused-vars
      const filteredStops = state.flightResults.data.filter((result) => {
        return result.data.toLowerCase().includes(action.payload.toLowerCase());
        // user.name.toLowerCase().includes(action.payload.toLowerCase());
      });
      return {
        ...state,
        filteredUsers:
          // eslint-disable-next-line no-undef
          action.payload.length > 0 ? filteredUsers : [...state.users],
      };
    },

    setTinfo: (state, action) => {
      state.tinfo = action.payload;
    },
    setAirlineFilterParams: (state, action) => {
      state.filters.airline.push(action.payload);
    },
    setStopsFilterParams: (state, action) => {
      state.filters.stops = action.payload;
    },
    setPriceRangeFilterParams: (state, action) => {
      state.filters.pricerange.push(action.payload);
    },
    setActiveFilterParams: (state, action) => {
      state.filters.active = action.payload;
    },
    setBookingData: (state, action) => {
      state.bookingData = action.payload;
    },
    setFlightoffer: (state, action) => {
      state.selectedFlightOption = action.payload;
    },
    setTriptype: (state, action) => {
      state.triptype = action.payload;
    },
    setTicketType: (state, action) => {
      state.ticketType = action.payload;
    },
    setPayment: (state, action) => {
      state.payment = action.payload;
    },
    setselectedTicketType: (state, action) => {
      state.selectedTicketType = action.payload;
    },
    minusAdult: (state, action) => {
      state.numberofAdults = action.payload;
    },
    addAdult: (state, action) => {
      state.numberofAdults = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    minusChild: (state, action) => {
      state.numberofChildren = action.payload;
    },
    addChild: (state, action) => {
      state.numberofChildren = action.payload;
    },
    minusInfant: (state, action) => {
      state.numberofInfants = action.payload;
    },
    addInfant: (state, action) => {
      state.numberofInfants = action.payload;
    },
    minusHeldInfant: (state, action) => {
      state.numberofHeldInfants = action.payload;
    },
    addHeldInfant: (state, action) => {
      state.numberofHeldInfants = action.payload;
    },
    selectFirstDeparture: (state, action) => {
      state.selectedDeparture.one = action.payload;
    },
    selectSecondDeparture: (state, action) => {
      state.selectedDeparture.two = action.payload;
    },
    selectThirdDeparture: (state, action) => {
      state.selectedDeparture.three = action.payload;
    },
    selectFouthDeparture: (state, action) => {
      state.selectedDeparture.four = action.payload;
    },
    selectFifthDeparture: (state, action) => {
      state.selectedDeparture.five = action.payload;
    },
    selectFirstArrival: (state, action) => {
      state.selectedArrival.one = action.payload;
    },
    selectSecondArrival: (state, action) => {
      state.selectedArrival.two = action.payload;
    },
    selectThirdArrival: (state, action) => {
      state.selectedArrival.three = action.payload;
    },
    selectFouthArrival: (state, action) => {
      state.selectedArrival.four = action.payload;
    },
    selectFifthArrival: (state, action) => {
      state.selectedArrival.five = action.payload;
    },

    selectFirstDate: (state, action) => {
      if (state.triptype !== "roundTrip") {
        state.date.one = action.payload;
      } else {
        state.rounddate = action.payload;
      }
    },

    selectRound1: (state, action) => {
      state.rounddate[0] = action.payload;
    },
    selectRound2: (state, action) => {
      state.rounddate[1] = action.payload;
    },
    selectSecondDate: (state, action) => {
      state.date.two = action.payload;
    },
    selectThirdDate: (state, action) => {
      state.date.three = action.payload;
    },
    selectFourthtDate: (state, action) => {
      state.date.four = action.payload;
    },
    selectFifthDate: (state, action) => {
      state.date.five = action.payload;
    },
    selectFDate: (state, action) => {
      state.fdate = action.payload;
    },
  },
  extraReducers: {
    [setFlightPayment.pending]: (state) => {
      state.flightLoader = true;
    },
    [setFlightPayment.fulfilled]: (state, action) => {
      state.flightLoader = false;
      state.paid = action.payload;
    },
    [setFlightPayment.rejected]: (state) => {
      state.flightLoader = false;
    },

    [getPopularTrips.pending]: (state) => {
      state.ptloading = true;
    },
    [getPopularTrips.fulfilled]: (state, action) => {
      state.populartrips = action.payload;
      state.ptloading = false;
    },
    [getPopularTrips.rejected]: (state) => {
      state.ptloading = false;
    },
    [getBlogs.pending]: () => {},
    [getBlogs.fulfilled]: (state, action) => {
      state.blogs = action.payload;
    },
    [getBlogs.rejected]: () => {},

    [PlaneSearch.pending]: (state) => {
      state.flightLoader = true;
      state.error = false;
      state.errorcode = null;
    },
    [PlaneSearch.fulfilled]: (state, action) => {
      state.flightResults = action.payload;
      state.flightLoader = false;
      state.isError = false;
      state.errorcode = null;
      state.error = false;
      state.amadeusError = null;
    },
    [PlaneSearch.rejected]: (state, action) => {
      state.flightLoader = false;
      state.isError = true;
      state.error = true;
      state.amadeusError = action.error;
    },
    [ConfirmPrice.pending]: (state) => {
      state.confirmationLoader = true;
      state.error = false;
      state.isError = false;
      state.amadeusError = null;
    },
    [ConfirmPrice.fulfilled]: (state, action) => {
      state.confirmedFlight = action.payload;
      state.confirmationLoader = false;
      state.error = false;
    },
    [ConfirmPrice.rejected]: (state, action) => {
      state.confirmationLoader = false;
      state.isError = true;
      state.error = true;
      state.amadeusError = action.error;
    },
    [Seatmap.pending]: (state) => {
      state.confirmationLoader = true;
      state.error = false;
    },
    [Seatmap.fulfilled]: (state, action) => {
      state.seatmap = action.payload;
      state.confirmationLoader = false;
      state.error = false;
    },
    [Seatmap.rejected]: (state) => {
      state.confirmationLoader = false;
      // state.error = true;
      // state.amadeusError = action.error;
    },
    [GetChargableBags.pending]: (state) => {
      state.confirmationLoader = true;
      state.error = false;
    },
    [GetChargableBags.fulfilled]: (state, action) => {
      state.chargablebags = action.payload;
      state.confirmationLoader = false;
      state.error = false;
    },
    [GetChargableBags.rejected]: (state, action) => {
      state.confirmationLoader = false;
      state.error = true;
      state.amadeusError = action.error;
    },
    [FareRules.pending]: (state) => {
      state.confirmationLoader = true;
      state.error = false;
    },
    [FareRules.fulfilled]: (state, action) => {
      state.farerules = action.payload;
      state.confirmationLoader = false;
      state.error = false;
    },
    [FareRules.rejected]: (state, action) => {
      state.confirmationLoader = false;
      state.error = true;
      state.amadeusError = action.error;
    },
    [OrderFlight.pending]: (state) => {
      state.orderLoader = true;
      state.error = false;
    },
    [OrderFlight.fulfilled]: (state, action) => {
      state.order = action.payload;
      state.orderLoader = false;
      state.error = false;
    },
    [OrderFlight.rejected]: (state, action) => {
      state.orderLoader = false;
      state.isError = true;
      state.error = true;
      state.amadeusError = action.error;
    },

    [checkServerStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [checkServerStatus.rejected]: (state, action) => {
      state.loading = true;
      state.checkError = action.error.message;
    },
    [checkServerStatus.fulfilled]: (state, action) => {
      state.loading = true;
      state.data = action.payload;
    },
  },
});
export const {
  setSeatData,
  selectFirstDate,
  selectSecondDate,
  selectThirdDate,
  selectFourthtDate,
  selectFifthDate,
  selectFirstArrival,
  selectSecondArrival,
  selectThirdArrival,
  selectFouthArrival,
  selectFifthArrival,
  selectFirstDeparture,
  selectSecondDeparture,
  selectThirdDeparture,
  selectFifthDeparture,
  setTriptype,
  setTicketType,
  setselectedTicketType,
  selectFouthDeparture,
  minusAdult,
  addAdult,
  addChild,
  minusChild,
  addInfant,
  minusInfant,
  addHeldInfant,
  minusHeldInfant,
  setFlightoffer,
  setBookingData,
  setEmail,
  selectFDate,
  setPayment,
  selectRound1,
  selectRound2,
  setAirlineFilterParams,
  setStopsFilterParams,
  setPriceRangeFilterParams,
  setActiveFilterParams,
  setTinfo,
  filterBy,
  sortBy,
  setStop,
  setFlightlist,
  setOrigindestination,
  reset,
  setError,
  updateAdditionalService,
  removeSeats,
  setFlightDataRedux,
  clearConfirmed,
} = flightSlice.actions;

export default flightSlice.reducer;
