export const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

const storedUserAgent =
  typeof sessionStorage !== "undefined"
    ? sessionStorage.getItem("userAgent")
    : null;
const Amaref =
  typeof sessionStorage !== "undefined"
    ? sessionStorage.getItem("amaref")
    : null;
export const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "x-ota-useragent": storedUserAgent,
  "ama-client-ref": Amaref,
};

export async function setAuthHeaders(headersObject, userAgent) {
  let headers = { ...headersObject };
  if (userAgent && headersObject) {
    // eslint-disable-next-line camelcase
    headers = {
      ...headers,
      // eslint-disable-next-line camelcase
      "x-ota-useragent": storedUserAgent,
    };
  }
  return headers;
}

export const authedHeader = (headersObject) => {
  let headers = { ...headersObject };
  if (localStorage.token) {
    headers = {
      ...headers,
      Authorization: `Bearer 122|4wm5LMjQTi8RM0dnKDroFchbY8QYLsuzLSijIUwo`,
    };
  }
  return headers;
};
