import { configureStore } from "@reduxjs/toolkit";
import flightReducer from "../features/flight/flightSlice";
import sidebarReducer from "../features/sidebar/sidebarSlice";
import getQuoteReducer from "../features/insurance/getQuoteSlice";
import individualReducer from "../features/insurance/individualSlice";
import familyReducer from "../features/insurance/familySlice";
import authReducer from "../features/auth/authSlice";
import stayReducer from "../features/stays/staySlice";
import experienceReducer from "../features/experience/experienceSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import paymentReducer from "../features/payment/payment";
import advertReducer from "../features/advert/advert";

export const store = configureStore({
  reducer: {
    flight: flightReducer,
    sidebar: sidebarReducer,
    getQuote: getQuoteReducer,
    individual: individualReducer,
    family: familyReducer,
    auth: authReducer,
    stay: stayReducer,
    experience: experienceReducer,
    dashboard: dashboardReducer,
    payment: paymentReducer,
    advert: advertReducer,
  },
});
