/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import { GetQuote, AddDataToQuote } from "./insuranceThunk";

const initialState = {
  payment: "",
  insuranceGetQuoteLoader: false,
  quote: null,
  bookedData: null,
  showQuote: false,
};

export const getQuoteSlice = createSlice({
  name: "getQuote",
  initialState,
  reducers: {
    setPayment: (state, action) => {
      state.payment = action.payload;
    },

    setshowQuote: (state, action) => {
      state.showQuote = action.payload;
    },
    setQuote: (state, action) => {
      state.quote = action.payload;
    },
  },
  extraReducers: {
    [GetQuote.pending]: (state) => {
      state.insuranceGetQuoteLoader = true;
    },
    [GetQuote.fulfilled]: (state, action) => {
      state.quote = action.payload;
      state.showQuote = true;
      state.insuranceGetQuoteLoader = false;
      // toast.success(action.payload.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
    [GetQuote.rejected]: (state, action) => {
      state.insuranceGetQuoteLoader = false;
      state.errormessage = "Error Processing request";
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [AddDataToQuote.pending]: (state) => {
      state.insuranceGetQuoteLoader = true;
    },
    [AddDataToQuote.fulfilled]: (state, action) => {
      state.quote = action.payload;
      state.showQuote = true;
      state.insuranceGetQuoteLoader = false;
      // toast.success("Data Submitted", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
    [AddDataToQuote.rejected]: (state, action) => {
      state.insuranceGetQuoteLoader = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  },
});
export const {
  setPayment,
  setQuote,
  // get quote form
  setshowQuote,
} = getQuoteSlice.actions;

export default getQuoteSlice.reducer;
