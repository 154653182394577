/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Router from "next/router";
import { BookIndividualPlan } from "./insuranceThunk";

const initialState = {
  dataIndividual: null,
  insuranceIndividualLoader: false,
  individualBookingIds: null,
  Title: "",
  FirstName: "",
  MiddleName: "",
  LastName: "",
  DOB: "",
  Gender: "",
  Phone: "",
  Email: "",
  Occupation: "",
  MaritalStatus: "",
  AnyCondition: "",
  MedicalCondition: "",
  Passport: "",
  State: "",
  Address: "",
  ZipCode: "",
  KinName: "",
  KinShip: "",
  KinAddress: "",
  KinPhone: "",
  // error message
  ShowTitleError: false,
  ShowFirstNameError: false,
  ShowMiddleNameError: false,
  ShowLastNameError: false,
  ShowDOBError: false,
  ShowGenderError: false,
  ShowPhoneError: false,
  ShowEmailError: false,
  ShowOccupationError: false,
  ShowMaritalStatusError: false,
  ShowAnyConditionError: false,
  ShowMedicalConditionError: false,
  ShowPassportError: false,
  ShowStateError: false,
  ShowAddressError: false,
  ShowZipCodeError: false,
  ShowKinNameError: false,
  ShowKinShipError: false,
  ShowKinAddressError: false,
  ShowKinPhoneError: false,
};

export const individualSlice = createSlice({
  name: "individual",
  initialState,
  reducers: {
    setDataIndividual: (state, action) => {
      state.dataIndividual = action.payload;
    },
    setTitle: (state, action) => {
      state.Title = action.payload;
    },
    setFirstName: (state, action) => {
      state.FirstName = action.payload;
    },
    setMiddleName: (state, action) => {
      state.MiddleName = action.payload;
    },
    setLastName: (state, action) => {
      state.LastName = action.payload;
    },
    setDOB: (state, action) => {
      state.DOB = action.payload;
    },
    setGender: (state, action) => {
      state.Gender = action.payload;
    },
    setPhone: (state, action) => {
      state.Phone = action.payload;
    },
    setEmail: (state, action) => {
      state.Email = action.payload;
    },
    setOccupation: (state, action) => {
      state.Occupation = action.payload;
    },
    setMaritalStatus: (state, action) => {
      state.MaritalStatus = action.payload;
    },
    setAnyCondition: (state, action) => {
      state.AnyCondition = action.payload;
    },
    setMedicalCondition: (state, action) => {
      state.MedicalCondition = action.payload;
    },
    setPassport: (state, action) => {
      state.Passport = action.payload;
    },
    setState: (state, action) => {
      state.State = action.payload;
    },
    setAddress: (state, action) => {
      state.Address = action.payload;
    },
    setZipCode: (state, action) => {
      state.ZipCode = action.payload;
    },
    setKinName: (state, action) => {
      state.KinName = action.payload;
    },
    setKinShip: (state, action) => {
      state.KinShip = action.payload;
    },
    setKinAddress: (state, action) => {
      state.KinAddress = action.payload;
    },
    setKinPhone: (state, action) => {
      state.KinPhone = action.payload;
    },

    // error message
    setShowTitleError: (state, action) => {
      state.ShowTitleError = action.payload;
    },
    setShowFirstNameError: (state, action) => {
      state.ShowFirstNameError = action.payload;
    },
    setShowMiddleNameError: (state, action) => {
      state.ShowMiddleNameError = action.payload;
    },
    setShowLastNameError: (state, action) => {
      state.ShowLastNameError = action.payload;
    },
    setShowDOBError: (state, action) => {
      state.ShowDOBError = action.payload;
    },
    setShowGenderError: (state, action) => {
      state.ShowGenderError = action.payload;
    },
    setShowPhoneError: (state, action) => {
      state.ShowPhoneError = action.payload;
    },
    setShowEmailError: (state, action) => {
      state.ShowEmailError = action.payload;
    },
    setShowOccupationError: (state, action) => {
      state.ShowOccupationError = action.payload;
    },
    setShowMaritalStatusError: (state, action) => {
      state.ShowMaritalStatusError = action.payload;
    },
    setShowAnyConditionError: (state, action) => {
      state.ShowAnyConditionError = action.payload;
    },
    setShowMedicalConditionError: (state, action) => {
      state.ShowMedicalConditionError = action.payload;
    },
    setShowPassportError: (state, action) => {
      state.ShowPassportError = action.payload;
    },
    setShowStateError: (state, action) => {
      state.ShowStateError = action.payload;
    },
    setShowAddressError: (state, action) => {
      state.ShowAddressError = action.payload;
    },
    setShowZipCodeError: (state, action) => {
      state.ShowZipCodeError = action.payload;
    },
    setShowKinNameError: (state, action) => {
      state.ShowKinNameError = action.payload;
    },
    setShowKinShipError: (state, action) => {
      state.ShowKinShipError = action.payload;
    },
    setShowKinAddressError: (state, action) => {
      state.ShowKinAddressError = action.payload;
    },
    setShowKinPhoneError: (state, action) => {
      state.ShowKinPhoneError = action.payload;
    },
  },
  extraReducers: {
    [BookIndividualPlan.pending]: (state) => {
      state.insuranceIndividualLoader = true;
    },
    [BookIndividualPlan.fulfilled]: (state, action) => {
      state.individualBookingIds = action.payload;
      Router.push("/insurance/confirmation");
      state.insuranceIndividualLoader = false;
    },
    [BookIndividualPlan.rejected]: (state) => {
      Router.push("/insurance/error");
      state.insuranceIndividualLoader = false;
      state.errormessage = "Error Processing request";
      toast.error("Error Processing request", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  },
});
export const {
  setDataIndividual,
  setTitle,
  setFirstName,
  setMiddleName,
  setLastName,
  setDOB,
  setGender,
  setPhone,
  setEmail,
  setOccupation,
  setMaritalStatus,
  setAnyCondition,
  setMedicalCondition,
  setPassport,
  setState,
  setAddress,
  setZipCode,
  setKinName,
  setKinShip,
  setKinAddress,
  setKinPhone,
  // error
  setShowTitleError,
  setShowFirstNameError,
  setShowMiddleNameError,
  setShowLastNameError,
  setShowDOBError,
  setShowGenderError,
  setShowPhoneError,
  setShowEmailError,
  setShowOccupationError,
  setShowMaritalStatusError,
  setShowAnyConditionError,
  setShowMedicalConditionError,
  setShowPassportError,
  setShowStateError,
  setShowAddressError,
  setShowZipCodeError,
  setShowKinNameError,
  setShowKinShipError,
  setShowKinAddressError,
  setShowKinPhoneError,
} = individualSlice.actions;

export default individualSlice.reducer;
