import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { setCurrency } from "../../features/auth/authSlice";

function CurrencyDropdown({ currency, currentpage }) {
  const dispatch = useDispatch();

  const currencySetter = (value) => {
    dispatch(setCurrency(value));
    localStorage.setItem("selectedCurrency", value);
  };
  return (
    <ul className={styles.currencyDropdown}>
      {currentpage === "insurance" && (
        <li
          onClick={() => currencySetter("NGN")}
          className={`${styles.options} ${
            currency === "NGN" ? styles.active : null
          }`}
        >
          Nigerian Naira (NGN)
        </li>
      )}
      {currentpage === "flight" && (
        <>
          <li
            onClick={() => currencySetter("NGN")}
            className={`${styles.options} ${
              currency === "NGN" ? styles.active : null
            }`}
          >
            Nigerian Naira (NGN)
          </li>
          <li
            onClick={() => currencySetter("USD")}
            className={`${styles.options} ${
              currency === "USD" ? styles.active : null
            }`}
          >
            United States Dollar (USD)
          </li>
          {/* <li
            onClick={() => currencySetter("GHS")}
            className={`${styles.options} ${
              currency === "GHS" ? styles.active : null
            }`}
          >
            Ghanaian Cedis (GHS)
          </li>
          <li
            onClick={() => currencySetter("XOF")}
            className={`${styles.options} ${
              currency === "XOF" ? styles.active : null
            }`}
          >
            West African Franc (XOF)
          </li>
          <li
            onClick={() => currencySetter("XAF")}
            className={`${styles.options} ${
              currency === "XAF" ? styles.active : null
            }`}
          >
            Central African Franc (XAF)
          </li> */}
        </>
      )}
    </ul>
  );
}
CurrencyDropdown.propTypes = {
  currency: PropTypes.string.isRequired,
  currentpage: PropTypes.string.isRequired,
};

export default CurrencyDropdown;
