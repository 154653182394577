"use client";

import React from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { hasCookie, setCookie } from "cookies-next";

// eslint-disable-next-line react/prop-types

// eslint-disable-next-line react/prop-types
function CookieConsent({ setShowCustomize }) {
  const [showConsent, setShowConsent] = React.useState(true);
  const { t } = useTranslation();
  const handleCustomize = () => {
    setShowConsent(false);
    setShowCustomize(true);
  };

  React.useEffect(() => {
    setShowConsent(hasCookie("localConsent"));
  }, []);
  const acceptCookie = () => {
    setShowConsent(true);
    setCookie("localConsent", "true", {});
  };
  const rejectAll = () => {
    setShowConsent(true);
    setCookie("localConsent", "true", {});
  };
  if (showConsent) {
    return null;
  }
  return (
    <div className=" fixed bottom-0 w-full z-10 bg-white shadow-2xl border-t border-gray-300  py-8 px-4 sm:px-5 ">
      {/* Modal content goes here */}
      {/* <div
        className=" absolute right-2 top-2 p-2 rounded-[3px] hover:bg-[#f4f4f4] cursor-pointer "
        onClick={() => setCookieOpen(false)}
      >
        <FaTimes className="w-5 h-5 text-zinc-500" />
      </div> */}
      <div className=" md:pl-[100px] p-2 w-full overflow-hidden">
        <h2 className="text-2xl sm:text-3xl font-semibold text-gray-900 font-Fira">
          {t("yourPrivacyYourChoice")}
        </h2>
        <div className="grid md:grid-cols-12 grid-cols-6 gap-3">
          <div className=" md:col-span-6 col-span-full">
            <p className=" text-xs sm:text-sm">
              {t("weuse")}{" "}
              <span className=" text-tramangoOrange underline">
                {t("cookies")}
              </span>{" "}
              {t("andOtherTech")}{" "}
              <span className=" text-tramangoOrange wh underline">
                {t("privacyPolicy")}
              </span>
              {t("ifyouaccept")}&apos;{t("alsouse")}{" "}
              <span className=" text-tramangoOrange whitespace-nowrap underline">
                {t("privacysettings")}
              </span>
              .
            </p>
          </div>

          <div className="md:col-span-2 col-span-2 flex items-center justify-center">
            <button
              type="button"
              onClick={() => handleCustomize()}
              className={`active:scale-95 max-h-[44.3px] flex w-full  items-center justify-center rounded-md py-3 px-5 text-sm focus:ring-2 focus:ring-tramangoOrange focus:ring-offset-2 border border-tramangoOrange  text-zinc-900 hover:text-white bg-transparent hover:bg-tramangoOrange transition-colors duration-500 `}
            >
              {t("customize")}
            </button>
          </div>
          <div className="md:col-span-2 col-span-2 flex items-center justify-center">
            <button
              type="button"
              className="active:scale-95 max-h-[44.3px] flex w-full  items-center justify-center  rounded-md py-3 px-5 text-sm focus:ring-2 focus:ring-tramangoOrange focus:ring-offset-2 border border-tramangoOrange  text-zinc-900 hover:text-white bg-transparent hover:bg-tramangoOrange transition-colors duration-500"
              onClick={() => rejectAll()}
            >
              {t("rejectall")}
            </button>
          </div>
          <div className="md:col-span-2 col-span-2 flex items-center justify-center">
            <button
              type="button"
              className={`active:scale-95 max-h-[44.3px] w-full rounded-md py-3 px-5 text-sm flex  items-center justify-center transition-colors focus:ring-2 focus:ring-tramangoOrange focus:ring-offset-2  text-white bg-tramangoOrange hover:bg-zin `}
              onClick={() => acceptCookie()}
            >
              {t("accept")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

CookieConsent.propTypes = {
  // setCookieOpen: PropTypes.bool.isRequired, // New boolean prop
  setShowCustomize: PropTypes.bool.isRequired, // New boolean prop
};

export default CookieConsent;
