import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/paymentAPI";

export const PayForABooking = createAsyncThunk(
  "PAY FOR A BOOKING",
  async (payload) => {
    const response = await api.payforABooking(payload);
    return response;
  }
);

export const bankCheck = createAsyncThunk("BANK CHECK", async (payload) => {
  const response = await api.bankCheck(payload);
  return response;
});

export const SelectPaymentMethod = createAsyncThunk(
  "SELECT PAYMENT",
  async (payload) => {
    const response = await api.selectPaymentMethod(payload);
    return response;
  }
);

export const GetFlutterWave = createAsyncThunk(
  "GET FLUTTERWAVE",
  async (payload) => {
    const response = await api.getFlutterWave(payload);
    return response;
  }
);

export const GetPayStack = createAsyncThunk("GET PAYSTACK", async (payload) => {
  const response = await api.getPaystack(payload);
  return response;
});

export const VerifyPayStack = createAsyncThunk(
  "VERIFY PAYSTACK",
  async (payload) => {
    const response = await api.verifyPaystack(payload);
    return response;
  }
);

export const VerifyFlutterWave = createAsyncThunk(
  "VERIFY FLUTTERWAVE",
  async (payload) => {
    const response = await api.verifyFlutterWave(payload);
    return response;
  }
);

export const ApplyVoucher = createAsyncThunk(
  "APPLY VOUCHER",
  async (payload) => {
    const response = await api.applyVoucher(payload);
    return response;
  }
);

export const RemoveVoucher = createAsyncThunk(
  "REMOVE VOUCHER",
  async (payload) => {
    const response = await api.removeVoucher(payload);
    return response;
  }
);
