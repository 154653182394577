/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import {
  SelectPaymentMethod,
  GetFlutterWave,
  GetPayStack,
  VerifyPayStack,
  VerifyFlutterWave,
  PayForABooking,
  bankCheck,
  ApplyVoucher,
  RemoveVoucher,
} from "./paymentThunk";

const initialState = {
  payment: null,
  paystackLink: null,
  flutterwaveLink: null,
  paymentLoader: false,
  verified: null,
  booking: null,
  failed: false,
  failedErrorMessage: "",
  voucherLoader: false,
  voucherResponse: null,
  removeVoucherLoader: null,
  removeVoucherResponse: null,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setBank: (state, action) => {
      state.payment = action.payload;
    },
  },
  extraReducers: {
    [PayForABooking.pending]: (state) => {
      state.paymentLoader = true;
    },
    [PayForABooking.fulfilled]: (state, action) => {
      state.booking = action.payload;
      state.paymentLoader = false;
      // toast.success(action.payload.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
    [PayForABooking.rejected]: (state, action) => {
      state.paymentLoader = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [SelectPaymentMethod.pending]: (state) => {
      state.paymentLoader = true;
    },
    [SelectPaymentMethod.fulfilled]: (state, action) => {
      state.payment = action.payload.paymentMethod;
      state.paymentLoader = false;
      if (state.payment === "BANK") {
        state.failed = false;
      }
    },
    [SelectPaymentMethod.rejected]: (state, action) => {
      state.paymentLoader = false;
      state.failedErrorMessage = action?.error?.message;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (state.payment === "BANK") {
        state.failed = true;
      }
    },

    [GetFlutterWave.pending]: (state) => {
      state.paymentLoader = true;
    },
    [GetFlutterWave.fulfilled]: (state, action) => {
      state.flutterwaveLink = action.payload.link;
      state.paymentLoader = false;
      // toast.success(action.payload.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
    [GetFlutterWave.rejected]: (state, action) => {
      state.paymentLoader = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [GetPayStack.pending]: (state) => {
      state.paymentLoader = true;
    },
    [GetPayStack.fulfilled]: (state, action) => {
      state.paystackLink = action.payload.link;
      state.paymentLoader = false;
      // toast.success(action.payload.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
    [GetPayStack.rejected]: (state, action) => {
      state.paymentLoader = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [bankCheck.pending]: (state) => {
      state.paymentLoader = true;
    },
    [bankCheck.fulfilled]: (state, action) => {
      state.paymentLoader = false;
      state.verified = action.payload;
      // toast.success(action.payload.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      state.failed = false;
    },
    [bankCheck.rejected]: (state) => {
      state.paymentLoader = false;
      state.failed = true;
    },
    [VerifyPayStack.pending]: (state) => {
      state.paymentLoader = true;
    },
    [VerifyPayStack.fulfilled]: (state, action) => {
      state.paymentLoader = false;
      state.verified = action.payload;
      // toast.success(action.payload.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      state.failed = false;
    },
    [VerifyPayStack.rejected]: (state) => {
      state.paymentLoader = false;
      state.failed = true;
    },
    [VerifyFlutterWave.pending]: (state) => {
      state.paymentLoader = true;
    },
    [VerifyFlutterWave.fulfilled]: (state, action) => {
      state.paymentLoader = false;
      state.verified = action.payload;
      // toast.success(action.payload.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      state.failed = false;
    },
    [VerifyFlutterWave.rejected]: (state) => {
      state.paymentLoader = false;
      state.failed = true;
    },

    [ApplyVoucher.pending]: (state) => {
      state.voucherLoader = true;
    },
    [ApplyVoucher.fulfilled]: (state, action) => {
      state.voucherLoader = false;
      state.voucherResponse = action.payload;
      // toast.success(action.payload.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
    [ApplyVoucher.rejected]: (state, action) => {
      state.voucherLoader = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [RemoveVoucher.pending]: (state) => {
      state.removeVoucherLoader = true;
    },
    [RemoveVoucher.fulfilled]: (state, action) => {
      state.removeVoucherLoader = false;
      state.voucherResponse = action.payload;
    },
    [RemoveVoucher.rejected]: (state, action) => {
      state.removeVoucherLoader = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  },
});

export const { setBank } = paymentSlice.actions;

export default paymentSlice.reducer;
