import { createAsyncThunk } from "@reduxjs/toolkit";
import Router from "next/router";
import * as api from "../../services/insuranceAPI";

export const GetQuote = createAsyncThunk("GET QUOTE", async (payload) => {
  const response = await api.getQuote(payload);
  return response;
});

export const BookIndividualPlan = createAsyncThunk(
  "BOOK INDIVIDUAL PLAN",
  async (payload) => {
    const response = await api.bookIndividualPlan(payload);
    Router.push("/insurance/makepayment");
    return response;
  }
);

export const BookFamilyPlan = createAsyncThunk(
  "BOOK FAMILY PLAN",
  async (payload) => {
    const response = await api.bookFamilyPlan(payload);
    Router.push("/insurance/makepayment");
    return response;
  }
);

export const PaymentReference = createAsyncThunk(
  "BOOKED INSURANCE PAYMENT REFERENCE",
  async (payload) => {
    const response = await api.paymentReference(payload);
    return response;
  }
);

export const InsuranceConfirmationMail = createAsyncThunk(
  "BOOKED INSURANCE TRAVELER DETAILS",
  async (payload) => {
    const response = await api.insuranceConfirmationMail(payload);
    return response;
  }
);

export const AddDataToQuote = createAsyncThunk(
  "ADD DATA TO QUOTE",
  async (payload) => {
    const response = await api.addDatatoQuote(payload);
    Router.push(`/insurance/makepayment`);
    return response;
  }
);
