/* eslint-disable no-unused-vars */
import axios from "axios";
import { baseURL, defaultHeaders, setAuthHeaders } from "./axiosDefaults";

export const purchaseExperience = (payload) => {
  const URL = `${baseURL}/api/experienceBooked`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const experienceSearch = (payload) => {
  const { query } = payload;
  const URL = `${baseURL}/api/experienceSearch?searchTerm=${query}`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const experienceToursSearch = (payload) => {
  const { query } = payload;
  const URL = `${baseURL}/api/experienceCategorySearch/Tours?searchTerm=Escaperoom`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const experienceRelaxationSearch = (payload) => {
  const { query } = payload;
  const URL = `${baseURL}/api/experienceCategorySearch/Relaxation?searchTerm=Escaperoom`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const experienceOutdoorSearch = (payload) => {
  const { query } = payload;
  const URL = `${baseURL}/api/experienceCategorySearch/OutdoorAndSport?searchTerm=Escape room`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const experienceAttractionSearch = (payload) => {
  const { query } = payload;
  const URL = `${baseURL}/api/experienceCategorySearch/Attraction?searchTerm=Escape room`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

// GET KEYWORDS
export const getAttractions = (payload) => {
  const URL = `${baseURL}/api/experienceCategory/Attraction`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const getTours = (payload) => {
  const URL = `${baseURL}/api/experienceCategory/Tours`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const getRelaxation = (payload) => {
  const URL = `${baseURL}/api/experienceCategory/Relaxation`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const getOutdoorAndSport = (payload) => {
  const URL = `${baseURL}/api/experienceCategory/OutdoorAndSport`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const viewExperienceWithID = (payload) => {
  const URL = `${baseURL}/api/experienceView?id=${payload}`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.errors;
    });
};

export const getInformationalwithID = (payload) => {
  const URL = `${baseURL}/api/infoExpView?id=${payload}`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.message;
    });
};

export const getListingReviews = async (payload) => {
  const URL = `${baseURL}/api/v1/reviewExpStatusOpen?id=${payload}`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      data: payload,
    });

    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getListingReviewsNegative = async (payload) => {
  const URL = `${baseURL}/api/v1/reviewExpStatusOpen?id=${payload}&sort=negative`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      data: payload,
    });

    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getListingReviewsPositive = async (payload) => {
  const URL = `${baseURL}/api/v1/reviewExpStatusOpen?id=${payload}&sort=positive`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      data: payload,
    });

    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const rateExperience = (payload) => {
  const URL = `${baseURL}/api/v1/experienceRating`;

  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeaders),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
