import axios from "axios";
import { baseURL, defaultHeaders } from "./axiosDefaults";

export const getQuote = async (payload) => {
  const URL = `${baseURL}/insurance/quote`;

  try {
    const response = await axios(URL, {
      method: "POST",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const addDatatoQuote = async (payload) => {
  const URL = `${baseURL}/insurance/insurancedata`;

  try {
    const response = await axios(URL, {
      method: "POST",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const bookIndividualPlan = async (payload) => {
  const URL = `${baseURL}/api/v1/booked`;

  try {
    const response = await axios(URL, {
      method: "POST",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.data.Message;
  }
};

export const bookFamilyPlan = async (payload) => {
  const URL = `${baseURL}/api/v1/booked`;

  try {
    const response = await axios(URL, {
      method: "POST",

      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const paymentReference = async (payload) => {
  const URL = `${baseURL}/api/paymentMethod?paymentMethod=${payload.paymentMethod}&tramangoId=${payload.tramangoId}&transactionReference=${payload.transactionReference}`;

  try {
    const response = await axios(URL, {
      method: "GET",

      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const insuranceConfirmationMail = async (payload) => {
  const URL = `${baseURL}/api/v1/insuranceConfirmationMail`;
  try {
    const response = await axios(URL, {
      method: "POST",

      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
