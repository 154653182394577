import PropTypes from "prop-types";
import React from "react";
import Image from "next/image";
import Link from "next/link";
// import styles from "./styles.module.scss";

function ErrorCard({ title, image, subtitle, support }) {
  return (
    <div className=" relative min-h-screen bg-gray-50 flex flex-col items-center  py-12 sm:px-6 lg:px-8">
      <div className=" pb-[64px] p-[24px] w-full mx-auto max-w-[960px] ">
        <div className="sm-mx-auto sm:w-full sm:max-w-md">
          <div className="flex flex-col items-center justify-center">
            <div className=" mb-9">
              <Image
                height={250}
                width={300}
                className=" h-full w-full object-cover bg-transparent "
                src={image}
              />
            </div>
            <div className=" max-w-[470px] my-[24px]  ">
              <h2 className=" text-center">{title}</h2>
              <div className=" text-[16px] text-[rgb(79,94,113)] font-normal text-center leading-[24px]">
                {subtitle}
              </div>
            </div>
            {support && (
              <div className=" flex w-full justify-center ">
                <div className=" w-auto cursor-pointer ">
                  <Link href="https://support.tramango.com/">
                    <div className=" h-[44px] rounded-[3px] px-4 inline-flex justify-between items-center text-center max-w-full bg-tramangoOrange mb-[24px] w-auto text-[15px] font-medium ">
                      <div className=" align-middle h-full flex justify-between items-center">
                        <div className=" text-white flex items-center">
                          Contact Support
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorCard.defaultProps = {
  subtitle: null, // Provide an appropriate default value
};

ErrorCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  support: PropTypes.bool.isRequired,
};

export default ErrorCard;
