import axios from "axios";
import { baseURL, defaultHeaders, setAuthHeaders } from "./axiosDefaults";

export const staySearch = async (payload) => {
  const { iata, checkIn, checkOut } = payload;
  const URL = `${baseURL}/api/searchStaysHotels?iata=${iata}&checkIn=${checkIn}&checkOut=${checkOut}&bookingCurrency=USD`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      data: payload,
    });
    return response.data.data;
  } catch (error) {
    throw error.response.data.errors[0].code;
  }
};

export const getStayviaID = async (payload) => {
  const URL = `${baseURL}/api/staysView?id=${payload}`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
    });
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};

export const stayPriceConfirm = async (payload) => {
  const { baseCode, id, startDate, endDate } = payload;
  const URL = `${baseURL}/api/stayPriceConfirm?id=${id}&baseCode=${baseCode}&startDate=${startDate}&endDate=${endDate}`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
    });
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};

export const bookStay = async (payload) => {
  const URL = `${baseURL}/api/staysBooked`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: await setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};

export const bookStayAmadeus = async (payload) => {
  const URL = `${baseURL}/api/amadeus/hotels/book`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};

export const bookStayHotelsPro = async (payload) => {
  const URL = `${baseURL}/api/hotels-book`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};

export const getStayPerCity = async () => {
  const URL = `${baseURL}/api/v1/stay?city=Lagos`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
    });
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};

export const getStayListingDetails = async () => {
  const URL = `${baseURL}/api/v1/stay?city=Lagos`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
    });
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};

export const getListingReviews = async (payload) => {
  const URL = `${baseURL}/api/v1/reviewStaysStatusOpen?id=${payload}`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      data: payload,
    });

    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getListingReviewsNegative = async (payload) => {
  const URL = `${baseURL}/api/v1/reviewStaysStatusOpen?id=${payload}&sort=negative`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      data: payload,
    });

    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getListingReviewsPositive = async (payload) => {
  const URL = `${baseURL}/api/v1/reviewStaysStatusOpen?id=${payload}&sort=positive`;

  try {
    const response = await axios(URL, {
      method: "GET",
      headers: defaultHeaders,
      data: payload,
    });

    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const rateStays = async (payload) => {
  const URL = `${baseURL}/api/v1/staysRating`;

  try {
    const response = await axios(URL, {
      method: "POST",
      headers: setAuthHeaders(defaultHeaders),
      data: payload,
    });
    return response.data;
  } catch (error) {
    return console.error(error);
  }
};
