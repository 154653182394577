import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/advertAPI";

export const FrontPageAds = createAsyncThunk("GET FRONT PAGE ADS", async () => {
  const response = await api.getFrontPageAd();
  return response;
});

export const DashboardAds = createAsyncThunk("GET DASHBOARD ADS", async () => {
  const response = await api.getDashboardAd();
  return response;
});

export const ConfirmationAds = createAsyncThunk(
  "GET CONFIRMATION ADS",
  async () => {
    const response = await api.getBookingConfirmationAd();
    return response;
  }
);

export const FlightResultsAds = createAsyncThunk(
  "GET FLIGHTRESULTS ADS",
  async () => {
    const response = await api.getFlightResultsAd();
    return response;
  }
);
