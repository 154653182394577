/* eslint-disable camelcase */
import axios from "axios";
import { baseURL, defaultHeaders } from "./axiosDefaults";

export const payforABooking = async (payload) => {
  const { tramangoId } = payload;
  const URL = `${baseURL}/bookings/${tramangoId}/pay`;
  try {
    const response = await axios(URL, {
      method: "GET",
      withCredentials: true,
      headers: defaultHeaders,
    });
    return response.data;
  } catch (err) {
    throw err.response.data.error;
  }
};

export const selectPaymentMethod = async (payload) => {
  const URL = `${baseURL}/payment/select`;

  try {
    const response = await axios(URL, {
      method: "POST",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const getFlutterWave = async (payload) => {
  const URL = `${baseURL}/payment/flutterwave`;

  try {
    const response = await axios(URL, {
      method: "GET",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getPaystack = async (payload) => {
  const URL = `${baseURL}/payment/paystack`;

  try {
    const response = await axios(URL, {
      method: "GET",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const verifyPaystack = async (payload) => {
  const { trxref, reference } = payload;
  const URL = `${baseURL}/payment/psverify?trxref=${trxref}&reference=${reference}`;

  try {
    const response = await axios(URL, {
      method: "GET",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const verifyFlutterWave = async (payload) => {
  const { transaction_id, status, tx_ref } = payload;
  const URL = `${baseURL}/payment/flwverify?transaction_id=${transaction_id}&status=${status}&tx_ref=${tx_ref}`;

  try {
    const response = await axios(URL, {
      method: "GET",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const bankCheck = async (payload) => {
  const URL = `${baseURL}/payment/bankcheck`;
  try {
    const response = await axios(URL, {
      method: "GET",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const applyVoucher = async (payload) => {
  const URL = `${baseURL}/payment/vouchers/checkout`;

  try {
    const response = await axios(URL, {
      method: "POST",
      withCredentials: true,
      headers: defaultHeaders,
      data: payload,
    });

    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const removeVoucher = async () => {
  const URL = `${baseURL}/payment/vouchers/checkout`;

  try {
    const response = await axios(URL, {
      method: "DELETE",
      withCredentials: true,
      headers: defaultHeaders,
    });
    return response.data;
  } catch (error) {
    throw error.data;
  }
};
