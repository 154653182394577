// import PropTypes from "prop-types";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import {
  FaBars,
  FaTimes,
  FaCog,
  FaQuestionCircle,
  FaUserCircle,
  FaChevronDown,
} from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import Image from "next/image";

import { IoMdOptions } from "react-icons/io";
import { TbLogout2, TbWorldPin } from "react-icons/tb";

import {
  setCurrency,
  setLocale,
  setAuthModal,
} from "../../../features/auth/authSlice";
import { LogOut } from "../../../features/auth/authThunk";
import CurrencyDropdown from "../../../common/CurrencyDropdown";
import {
  useComponentVisible,
  useCurrencyVisible,
  useLanguageVisible,
  useSideBarVisible,
} from "../../../hooks/useComponentVisible";
import styles from "./styles.module.scss";
import SideBar from "../SideBar";
import LanguageDropdown from "../../../common/LanguageDropdown";
import logo from "../../../public/logos/tramango-logo-black.png";
import logochristmas from "../../../public/logos/Christmas.png";
import logoeaster from "../../../public/logos/Easter.png";
import logosallah from "../../../public/logos/Sallah.png";

// import LoginModal from "../../../pages/auth/loginModal";

// import GoogleTranslate from "../../../common/GoogleTranslate";

function NavBar() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  function getLogo() {
    const today = new Date();

    const holidays = [
      { month: 12, day: 25, before: 7, logo: logochristmas }, // Christmas
      { month: 4, day: 20, before: 7, logo: logosallah }, // Halloween
      { month: 3, day: 31, before: 7, logo: logoeaster }, // Independence Day
    ];

    const holiday = holidays.find((h) => {
      const holidayDate = new Date(today.getFullYear(), h.month - 1, h.day);
      const diffTime = Math.abs(holidayDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays <= h.before;
    });

    if (holiday) {
      return holiday.logo;
    }

    // Default logo
    return logo;
  }
  const { t } = useTranslation();
  const { location, currency, isLoading, locale, user } = useSelector(
    (state) => state.auth
  );
  const { activePage } = useSelector((state) => state.sidebar);
  const [settings, setSettings] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  const toggleIcon = { transform: "rotate(180deg)" };
  const signOut = () => {
    dispatch(LogOut());
    window.location.reload();
  };
  // to close the account dropdown menu when you click outside it
  const accountDropdownRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showAccountDropdown &&
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(e.target)
      ) {
        setShowAccountDropdown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showAccountDropdown]);

  // to close the settings dropdown menu when you click outside it
  const settingsDropdownRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the filter is open and the clicked target is not within the filter,
      // then close the filter
      if (
        settings &&
        settingsDropdownRef.current &&
        !settingsDropdownRef.current.contains(e.target)
      ) {
        setSettings(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
  }, [settings]);

  const {
    ref: travelHubref,
    isComponentVisible: travelHubVisible,
    setIsComponentVisible: setTravelHubVisible,
  } = useComponentVisible(false);

  // const userCountryCode = location?.country_code_iso3;
  const { ref, isCurrencyVisible, setIsCurrencyVisible } =
    useCurrencyVisible(false);

  const { langref, isLanguageVisible, setIsLanguageVisible } =
    useLanguageVisible(false);
  const { sidebarref, isSideBarVisible, setIsSideBarVisible } =
    useSideBarVisible(false);
  useEffect(() => {
    // When the component mounts, check if a language preference is stored in local storage
    // const storedLanguage = localStorage.getItem("selectedLanguage");
    const storedLanguage =
      typeof localStorage !== "undefined"
        ? localStorage.getItem("selectedLanguage")
        : null;

    if (storedLanguage) {
      // If a language preference is found, set it as the current language
      dispatch(setLocale(storedLanguage));
      i18n.changeLanguage(storedLanguage);
    }
  }, [locale]);

  useEffect(() => {
    const storedCurrency =
      typeof localStorage !== "undefined"
        ? localStorage.getItem("selectedCurrency")
        : null;

    if (storedCurrency) {
      // If a language preference is found, set it as the current language
      dispatch(setCurrency(storedCurrency));
    }
  }, [currency]);
  const defaultLocation = {
    country_code_iso3: "NGA",
    currency: "NGN",
    locale: "en",
  };
  useEffect(() => {
    const countryCode =
      location?.country_code_iso3 || defaultLocation.country_code_iso3;
    // const countryCode = userCountryCode;

    switch (countryCode) {
      case "NGA":
        dispatch(setCurrency("NGN"));
        dispatch(setLocale("en"));
        break;
      case "GHA":
        dispatch(setCurrency("USD"));
        dispatch(setLocale("en"));
        break;
      case "BEN":
      case "BFA":
      case "CIV":
      case "GNB":
      case "MLI":
      case "NER":
      case "SEN":
      case "TGO":
        dispatch(setCurrency("USD"));
        dispatch(setLocale("fr"));
        i18n.changeLanguage("fr");

        break;
      case "CMR":
      case "CAF":
      case "TCD":
      case "COG":
      case "GNQ":
      case "GAB":
        dispatch(setCurrency("USD"));
        dispatch(setLocale("fr"));
        i18n.changeLanguage("fr");

        break;
      default:
        dispatch(setCurrency("USD"));
        dispatch(setLocale("en"));
        break;
    }
  }, [location]);

  const SettingsMenu = () => {
    setSettings(!settings);
  };
  // Function to show the account dropdown
  const showAccountMenu = (e) => {
    e.stopPropagation();
    setShowAccountDropdown(!showAccountDropdown);
  };
  const BurgerMenu = () => {
    setIsSideBarVisible(!isSideBarVisible);
  };

  const showAuth = (e) => {
    e.preventDefault();
    dispatch(setAuthModal(true));
  };

  const handleLanguageChange = (val) => {
    // Update the Redux state with the selected language
    dispatch(setLocale(val));
    // Change the language using i18n
    i18n.changeLanguage(val);
    // Store the selected language in local storage
    localStorage.setItem("selectedLanguage", val);
  };

  const currencySetter = (value) => {
    dispatch(setCurrency(value));
    localStorage.setItem("selectedCurrency", value);
  };

  return (
    <>
      <nav className={`${styles.nav} dontPrint`}>
        <div className={styles.wrapper}>
          {/* logo and burger menu */}
          <div className={styles.left}>
            {!isSideBarVisible ? (
              <div className={styles.burgerToggle} onClick={BurgerMenu}>
                <FaBars className={styles.icons} />
              </div>
            ) : (
              <div className={styles.burgerToggle} onClick={BurgerMenu}>
                <FaTimes className={styles.icons} />
              </div>
            )}

            <div className={styles.logo}>
              <Link href="/">
                <Image
                  src={getLogo()}
                  alt="company logo"
                  loading="eager"
                  priority
                />
              </Link>
            </div>
          </div>
          {/* nav links */}
          <div className={styles.right}>
            <ul className={`${styles.navLinks} ${styles.large} `}>
              {!isLoading && location && (
                <li
                  className={styles.currency}
                  ref={ref}
                  onClick={() => setIsCurrencyVisible(!isCurrencyVisible)}
                >
                  <span className=" flex items-center">
                    <span>{currency}</span>
                    <FaChevronDown
                      className={styles.icon}
                      style={isCurrencyVisible ? toggleIcon : ""}
                    />
                  </span>

                  {isCurrencyVisible && (
                    <div className={styles.dropdown}>
                      <CurrencyDropdown
                        currency={currency}
                        currentpage={activePage}
                      />
                    </div>
                  )}
                </li>
              )}

              <li
                onClick={() => setIsLanguageVisible(!isLanguageVisible)}
                className={styles.language}
                ref={langref}
              >
                <span className=" flex items-center">
                  {locale.toUpperCase()}
                  <FaChevronDown
                    className={styles.icon}
                    style={isLanguageVisible ? toggleIcon : ""}
                  />
                </span>
                {isLanguageVisible && (
                  <div className={styles.dropdown}>
                    <LanguageDropdown />
                  </div>
                )}
              </li>
              {/* <GoogleTranslate /> */}
              {!user || user.adminType ? (
                <>
                  <li className={styles.signup} onClick={(e) => showAuth(e)}>
                    {/* <Link href="/auth/login">{t("login")}</Link> */}
                    {t("login")}
                  </li>
                  {/* <li className={styles.signup}>
                    <Link href="/auth/signup">{t("signup")}</Link>
                  </li> */}
                </>
              ) : (
                <>
                  {/* <li className={styles.login}>
                    <button type="button" onClick={() => signOut()}>
                      {t("logout")}
                    </button>
                  </li> */}
                  <div ref={travelHubref} className=" relative">
                    <button
                      onClick={() => setTravelHubVisible(!travelHubVisible)}
                      type="button"
                      className={`${styles.signup} `}
                    >
                      Travel Hub
                    </button>
                    {travelHubVisible && (
                      <div
                        className="origin-top-right z-20 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white border"
                        role="menu"
                      >
                        <div className="p-2 space-y-2">
                          <Link className="" href="/hub">
                            <div className="flex items-center cursor-pointer px-4 py-2 rounded-md text-sm hover:bg-tramangolightOrange w-full text-left">
                              <TbWorldPin className=" mr-2" size={20} />
                              <div>My Trips</div>
                            </div>
                          </Link>
                          <Link className="" href="/hub/preferences/">
                            <div className="flex items-center cursor-pointer px-4 py-2 rounded-md text-sm hover:bg-tramangolightOrange w-full text-left">
                              <IoMdOptions className=" mr-2" size={20} />
                              <div>Preferences</div>
                            </div>
                          </Link>

                          <button
                            type="button"
                            onClick={() => signOut()}
                            className="flex items-center cursor-pointer px-4 py-2 rounded-md text-sm hover:bg-tramangolightOrange w-full text-left"
                          >
                            <TbLogout2 className=" mr-2" size={20} />
                            <div> {t("logout")}</div>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </ul>

            <ul className={`${styles.navLinks} ${styles.mobile} `}>
              <li ref={settingsDropdownRef} className={styles.settings}>
                <FaCog className={styles.icons} onClick={SettingsMenu} />

                {settings && (
                  <ul
                    className={`${styles.dropdown} ${styles.mobileCurrency} `}
                  >
                    {!isLoading && location && (
                      <li className={styles.currency}>
                        <select
                          name="currency"
                          id="currency"
                          value={currency}
                          onChange={(e) => currencySetter(e.target.value)}
                        >
                          <option value="NGN">NGN</option>
                          <option value="USD">USD</option>
                          {/* <option value="GHS">GHS</option>
                          <option value="XOF">XOF</option>
                          <option value="XAF">XAF</option> */}
                        </select>
                      </li>
                    )}
                    <li className={styles.language}>
                      <select
                        name="language"
                        id="language"
                        value={locale}
                        onChange={(e) => handleLanguageChange(e.target.value)}
                      >
                        <option value="en">EN</option>
                        <option value="fr">FR</option>
                      </select>
                    </li>
                  </ul>
                )}
              </li>

              <li className={styles.questions}>
                <a
                  href="https://support.tramango.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaQuestionCircle className={styles.icons} />
                </a>
              </li>

              <li ref={accountDropdownRef} className={styles.account}>
                <FaUserCircle
                  className={styles.icons}
                  onClick={showAccountMenu}
                />

                {showAccountDropdown && (
                  <ul className={`${styles.dropdown} border`}>
                    {!user ? (
                      <>
                        <li
                          className={styles.login}
                          onClick={(e) => showAuth(e)}
                        >
                          Sign in
                          {/* <Link href="/auth/login">Log In</Link> */}
                        </li>
                        {/* <li className={styles.signup}>
                          <Link href="/auth/signup">Sign Up</Link>
                        </li> */}
                      </>
                    ) : (
                      <>
                        <li className={styles.signup}>
                          <Link href="/hub">My trips</Link>
                        </li>
                        <li className={styles.signup}>
                          <Link href="/hub">Preferences</Link>
                        </li>
                        <li className={styles.login}>
                          <button type="button" onClick={() => signOut()}>
                            Sign out
                          </button>
                        </li>
                      </>
                    )}
                    {/* <li className={styles.host}>
                      <a
                        href="https://tramango-vendor.netlify.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Become a Host
                      </a>
                    </li> */}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* sidebar on smaller screens */}
      {isSideBarVisible && <div className={`${styles.mobileOverlay}`} />}
      <div
        ref={sidebarref}
        className={`${styles.mobileSideBar} ${
          isSideBarVisible ? styles.active : ""
        }`}
      >
        {isSideBarVisible && <SideBar />}
      </div>
    </>
  );
}

export default NavBar;

NavBar.defaultProps = {
  session: null,
};
