import axios from "axios";
import { baseURL, defaultHeaders, setAuthHeaders } from "./axiosDefaults";

export const StartResetPassword = (payload) => {
  const URL = `${baseURL}/resetpassword`;
  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};
export const Subscribe = (payload) => {
  const URL = `${baseURL}/newsletter`;
  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const ConfirmResetPassword = (payload) => {
  const URL = `${baseURL}/resetpassword/code`;
  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const ResetPassword = (payload) => {
  const URL = `${baseURL}/resetpassword/password`;
  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const Login = (payload) => {
  const { email, password, rememberMe } = payload;
  const URL = `${baseURL}/login`;
  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: { email, password, rememberMe },
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const LoginGoogle = (payload) => {
  const URL = `${baseURL}/login/google`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

// export const checkSystem = () => {
//   const URL = `${baseURL}/check`;

//   return axios(URL, {
//     method: "GET",
//     headers: defaultHeaders,
//     withCredentials: true,
//   })
//     .then((response) => response.status)
//     .catch((error) => {
//       throw error.response.status;
//     });
// };

export const checkSystem = () => {
  const URL = `${baseURL}/check`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    withCredentials: true,
  })
    .then((response) => {
      if (response.status !== 200) {
        return true;
      }
      return false;
    })
    .catch(() => {
      return true;
    });
};

export const LogOut = (payload) => {
  const URL = `${baseURL}/logout`;

  return axios(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const Register = (payload) => {
  const URL = `${baseURL}/signup`;
  const { email, firstName, lastName, password } = payload;
  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: { email, firstName, lastName, password },
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const VerifyEmail = (payload) => {
  const { id, string } = payload;
  const URL = `${baseURL}/verify/${id}/${string}`;

  return axios(URL, {
    method: "GET",
    headers: defaultHeaders,
    data: payload,
    withCredentials: true,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data.error;
    });
};

export const Cookie = async () => {
  const URL = `${baseURL}/cookie`;
  try {
    const response = await axios(URL, {
      method: "GET",
      withCredentials: true,
      headers: defaultHeaders,
    });
    return response.data;
  } catch (err) {
    throw err.response.data.error;
  }
};

export const getUserData = () => {
  const URL = `${baseURL}/user`;

  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeaders),
    withCredentials: true,
  })
    .then((response) => response.data.user)
    .catch((error) => {
      throw error;
    });
};

export const updateUserProfileData = (payload) => {
  const { userType, userId, load } = payload;
  const URL = `${baseURL}/users/${userType}/${userId}`;
  return axios(URL, {
    method: "POST",
    headers: setAuthHeaders(defaultHeaders),
    withCredentials: true,
    data: load,
  })
    .then((response) => response.data.user)
    .catch((error) => {
      throw error;
    });
};

export const getLocationData = () => {
  const URL = "https://ipapi.co/json/";

  return axios(URL, {
    method: "GET",
    headers: setAuthHeaders(defaultHeaders),
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const forgotPassword = (payload) => {
  const URL = `${baseURL}/api/v1/forgot_password`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const verifyOtp = (payload) => {
  const URL = `${baseURL}/api/v1/check_reset_code`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const updatePassword = (payload) => {
  const URL = `${baseURL}/api/v1/update_password`;

  return axios(URL, {
    method: "POST",
    headers: defaultHeaders,
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
