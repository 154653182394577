/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  Login,
  Register,
  getUserData,
  getLocationData,
  forgotPassword,
  verifyOtp,
  updatePassword,
  VerifyEmail,
  LogOut,
  GoogleLogin,
  checkCookie,
  startResetPassword,
  confirmResetPassword,
  ResetPassword,
  checkSystem,
  Subscribe,
  updateUserData,
} from "./authThunk";

const initialState = {
  status: null,
  token: "",
  user: null,
  isLoading: false,
  location: "NGA",
  closestairport: null,
  agent: null,
  // the pageToPushTo is for when you ask the user to login or sign up and you want them to continue from where they stop
  // if it is set to null, then it should push them to the home page, otherwise it should push them to page saved
  // this will work for login, but i dunno yet how it will work for signup
  // i think when the user gets verification link, the link takes them to the login page, if thats the case then they should be redirected to where they stopped.
  // the pageToPushTo should return back to null once they login.
  pageToPushTo: null,
  currency: null,
  locale: "en",
  currencydropdown: false,
  showForgotPasswordForm: true,
  showOtpEnterOtpForm: false,
  showChangePasswordForm: false,
  showCongratulatoryMessage: false,
  Created: false,
  cookieStatus: null,
  EmailVerification: null,
  AuthModal: false,
  LoginFormM: false,
  SiginupFormM: false,
  accountExists: null,
  accountConfirmed: null,
  accountReseted: null,
  verifyemail: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    forgotPasswordCleanup: (state) => {
      state.showCongratulatoryMessage = false;
      state.accountExists = null;
      state.accountConfirmed = null;
      state.accountReseted = null;
      state.showOtpEnterOtpForm = false;
    },
    setAuthModal: (state, action) => {
      state.AuthModal = action.payload;
    },
    setLoginModal: (state, action) => {
      state.LoginFormM = action.payload;
    },
    setSignUpModal: (state, action) => {
      state.SiginupFormM = action.payload;
    },
    setPageToPushTo: (state, action) => {
      state.pageToPushTo = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setAgent: (state, action) => {
      state.agent = action.payload;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setCurrencyDropDown: (state, action) => {
      state.currencydropdown = action.payload;
    },
    setAirport: (state, action) => {
      state.closestairport = action.payload;
    },
  },
  extraReducers: {
    [checkCookie.pending]: (state) => {
      state.isLoading = true;
    },
    [checkCookie.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cookieStatus = action.payload;
    },
    [checkCookie.rejected]: (state) => {
      state.isLoading = false;
    },
    [checkSystem.pending]: (state) => {
      state.isLoading = true;
    },
    [checkSystem.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.status = action.payload;
    },
    [checkSystem.rejected]: (state, action) => {
      state.isLoading = false;
      state.status = action.payload;
    },
    [startResetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [startResetPassword.fulfilled]: (state) => {
      state.isLoading = false;
      state.accountExists = true;
      state.accountConfirmed = false;
      state.accountReseted = false;
    },
    [startResetPassword.rejected]: (state) => {
      state.isLoading = false;
    },
    [confirmResetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [confirmResetPassword.fulfilled]: (state) => {
      state.isLoading = false;
      state.accountConfirmed = true;
      state.accountExists = false;
    },
    [confirmResetPassword.rejected]: (state) => {
      state.isLoading = false;
    },
    [ResetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [ResetPassword.fulfilled]: (state) => {
      state.isLoading = false;
      state.accountReseted = true;
      state.accountExists = false;
      state.accountConfirmed = false;
    },
    [ResetPassword.rejected]: (state) => {
      state.isLoading = false;
    },
    [getLocationData.pending]: (state) => {
      state.isLoading = true;
    },
    [getLocationData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.location = action.payload;
    },
    [getLocationData.rejected]: (state) => {
      state.isLoading = false;
    },
    [Login.pending]: (state) => {
      state.isLoading = true;
    },
    [Login.fulfilled]: (state, action) => {
      const { token } = action.payload;
      state.isLoading = false;
      state.token = token;
    },
    [Login.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [Subscribe.pending]: (state) => {
      state.isLoading = true;
    },
    [Subscribe.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success("Subscribed successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [Subscribe.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [updateUserData.pending]: (state) => {
      state.isLoading = true;
    },
    [updateUserData.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success("Data updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [updateUserData.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [GoogleLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [GoogleLogin.fulfilled]: (state, action) => {
      const { token } = action.payload;
      state.isLoading = false;
      state.token = token;
    },
    [GoogleLogin.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [LogOut.pending]: (state) => {
      state.isLoading = true;
    },
    [LogOut.fulfilled]: (state) => {
      state.isLoading = false;
      state.token = "";
      state.user = null;
    },
    [LogOut.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [Register.pending]: (state) => {
      state.isLoading = true;
    },
    [Register.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.Created = true;
      toast.success(action.payload.status, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [Register.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [VerifyEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [VerifyEmail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.EmailVerification = action.payload;
      toast.success(action.payload.status, {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [VerifyEmail.rejected]: (state) => {
      state.isLoading = false;
      // toast.error(action.error.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
    [getUserData.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserData.fulfilled]: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", true);

      state.isLoading = false;
    },
    [getUserData.rejected]: (state) => {
      state.isLoading = false;
    },
    [forgotPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.isLoading = false;
      const error = action.payload;
      if (action.payload && error?.response?.data?.error) {
        toast.error(error?.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Could not process request", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success("Email sent successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      state.showForgotPasswordForm = false;
      state.showOtpEnterOtpForm = true;
    },
    [verifyOtp.pending]: (state) => {
      state.isLoading = true;
    },
    [verifyOtp.rejected]: (state, action) => {
      state.isLoading = false;
      const error = action.payload;
      if (action.payload && error?.response?.data?.error) {
        toast.error(error?.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Could not process request", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    [verifyOtp.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success("Otp verified successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      state.showOtpEnterOtpForm = false;
      state.showChangePasswordForm = true;
    },
    [updatePassword.pending]: (state) => {
      state.isLoading = true;
    },
    [updatePassword.rejected]: (state, action) => {
      state.isLoading = false;
      const error = action.payload;
      if (action.payload && error?.response?.data?.error) {
        toast.error(error?.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Could not process request", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    [updatePassword.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success("Password updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      state.showChangePasswordForm = false;
      state.showCongratulatoryMessage = true;
    },
  },
});

export const {
  setPageToPushTo,
  setCurrency,
  setAirport,
  setLocale,
  setLoginModal,
  setAuthModal,
  setSignUpModal,
  setAgent,
  forgotPasswordCleanup,
} = authSlice.actions;
export default authSlice.reducer;
