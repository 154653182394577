/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import {
  getListingReviews,
  getListingReviewsNegative,
  getListingReviewsPositive,
  experienceSearch,
  experienceAttractionSearch,
  experienceOutdoorsSearch,
  experienceRelaxationSearch,
  experienceToursSearch,
  getAttractions,
  getOutdoor,
  getRelaxation,
  getTours,
  viewExperience,
  purchaseExperience,
  submitUserRatings,
  getInformationalwithID,
} from "./experienceThunk";

const initialState = {
  isLoading: false,
  isLoadingPositive: false,
  isLoadingNegative: false,
  listingReviews: null,
  listingReviewsPositive: null,
  listingReviewsNegative: null,
  listingReviewsLatest: null,
  selected: false,
  attractionloader: false,
  confirmation: null,
  results: null,
  data: null,
  selectedexperience: null,
  userRatings: null,
  selectedInformational: null,
};

export const experienceSlice = createSlice({
  name: "experience",
  initialState,
  reducers: {
    setListingReviewsLatest: (state, action) => {
      state.listingReviewsLatest = action.payload;
    },
  },
  extraReducers: {
    [purchaseExperience.pending]: (state) => {
      state.isLoading = true;
    },
    [purchaseExperience.rejected]: (state) => {
      state.isLoading = false;
    },
    [purchaseExperience.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.confirmation = action.payload;
    },
    [viewExperience.pending]: (state) => {
      state.isLoading = true;
    },
    [viewExperience.fulfilled]: (state, action) => {
      state.selectedexperience = action.payload;
      state.isLoading = false;
    },
    [viewExperience.rejected]: (state) => {
      state.isLoading = false;
    },
    [getListingReviews.pending]: (state) => {
      state.isLoading = true;
    },
    [getListingReviews.fulfilled]: (state, action) => {
      state.listingReviews = action.payload;
      state.isLoading = false;
    },
    [getListingReviews.rejected]: (state) => {
      state.isLoading = false;
    },
    [getListingReviewsPositive.pending]: (state) => {
      state.isLoadingPositive = true;
    },
    [getListingReviewsPositive.fulfilled]: (state, action) => {
      state.listingReviewsPositive = action.payload;
      state.isLoadingPositive = false;
    },
    [getListingReviewsPositive.rejected]: (state) => {
      state.isLoadingPositive = false;
    },
    [getListingReviewsNegative.pending]: (state) => {
      state.isLoadingNegative = true;
    },
    [getListingReviewsNegative.fulfilled]: (state, action) => {
      state.listingReviewsNegative = action.payload;
      state.isLoadingNegative = false;
    },
    [getListingReviewsNegative.rejected]: (state) => {
      state.isLoadingNegative = false;
    },
    [getRelaxation.pending]: (state) => {
      state.isLoading = true;
    },
    [getRelaxation.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getRelaxation.rejected]: (state) => {
      state.isLoading = false;
    },
    [getTours.pending]: (state) => {
      state.isLoading = true;
    },
    [getTours.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getTours.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAttractions.pending]: (state) => {
      state.attractionloader = true;
    },
    [getAttractions.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.attractionloader = false;
    },
    [getAttractions.rejected]: (state) => {
      state.attractionloader = false;
    },
    [getOutdoor.pending]: (state) => {
      state.isLoading = true;
    },
    [getOutdoor.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getOutdoor.rejected]: (state) => {
      state.isLoading = false;
    },
    [experienceSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [experienceSearch.fulfilled]: (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    },
    [experienceSearch.rejected]: (state) => {
      state.isLoading = false;
    },
    [experienceAttractionSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [experienceAttractionSearch.fulfilled]: (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    },
    [experienceAttractionSearch.rejected]: (state) => {
      state.isLoading = false;
    },
    [experienceOutdoorsSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [experienceOutdoorsSearch.fulfilled]: (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    },
    [experienceOutdoorsSearch.rejected]: (state) => {
      state.isLoading = false;
    },
    [experienceRelaxationSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [experienceRelaxationSearch.fulfilled]: (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    },
    [experienceRelaxationSearch.rejected]: (state) => {
      state.isLoading = false;
    },
    [experienceToursSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [experienceToursSearch.fulfilled]: (state, action) => {
      state.results = action.payload;
      state.isLoading = false;
    },
    [experienceToursSearch.rejected]: (state) => {
      state.isLoading = false;
    },
    [submitUserRatings.pending]: (state) => {
      state.isLoading = true;
    },
    [submitUserRatings.fulfilled]: (state, action) => {
      state.userRatings = action.payload;
      state.isLoading = false;
      toast.success("Reviews submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [submitUserRatings.rejected]: (state) => {
      state.isLoading = false;
      toast.error("Submit review failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getInformationalwithID.pending]: (state) => {
      state.loading = true;
      toast.error("Experience cant be fetched", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getInformationalwithID.fulfilled]: (state, action) => {
      state.selectedInformational = action.payload;
      state.loading = false;
      toast.success("Experience Fetched", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [getInformationalwithID.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export const { setListingReviewsLatest } = experienceSlice.actions;

export default experienceSlice.reducer;
